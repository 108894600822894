//External
import React, { Component } from 'react';
import Button from "@material-ui/core/es/Button/Button";
import Typography from "@material-ui/core/es/Typography";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {faDiscord, faFacebook, faPatreon, faSteam, faTwitch, faTwitter} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretSquareUp} from "@fortawesome/free-solid-svg-icons";
import Nav from "react-bootstrap/Nav";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Link from '@material-ui/core/Link';
import {colors} from "../Styles/colors";


class Footer extends Component {
    render() {
        return (
            <Container fluid style={{
                fontFamily: "DharmaGothicE-RegularItalic",
                paddingTop: '1.5rem',
                backgroundColor: '#30274D',}}>
                <Row style={{paddingBottom:'1rem'}}>
                    <Col md={4}>
                    <Typography variant="body2" style={{color: colors.mainWhite}}>
                        <a href="https://steamcommunity.com/groups/RolePlace" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faSteam} size="2x"/></a> &nbsp;
                        <a href="https://discord.gg/nHp7XWF" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faDiscord} size="2x"/></a> &nbsp;
                        {/*<FontAwesomeIcon icon={faFacebook} size="2x"/> &nbsp;*/}
                        <a href="https://www.twitch.tv/roleplace" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faTwitch} size="2x"/></a> &nbsp;
                        {/*<FontAwesomeIcon icon={faTwitter} size="2x"/> &nbsp;*/}
                        <a href="http://www.patreon.com/roleplace" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faPatreon} size="2x"/></a> &nbsp;
                    </Typography>
                    </Col>
                        <Col style={{justifyContent: 'right'}} md={{ span: 4, offset: 4 }}>
                            <div style={{textAlign: 'right'}}>
                            <Link href="/Home">

                            <FontAwesomeIcon icon={faCaretSquareUp} size="2x"/>

                            </Link>
                        </div>
                        </Col>

                </Row>
                <Row fluid style={{paddingBottom:'1rem'}}>
                    <Col md={4}>
                        <Link style={{color: colors.mainWhite}} href="/Home">Home</Link><br/>
                        <Link style={{color: colors.mainWhite}} href="Home#Staff" scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}> Staff</Link><br/>
                        <Link style={{color: colors.mainWhite}} href="Home#FAQ" scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}>FAQ</Link><br/>
                        <Link style={{color: colors.mainWhite}} href="/Register">Register</Link><br/>
                        <Link style={{color: colors.mainWhite}} href="/RecoverRequest">Password Recovery</Link><br/> <br/>
                        <Typography variant="body2">
                            &copy; 2021, Roleplace LLC, the Roleplace logo, and the mascot Penny are protected intellectual properties of Roleplace in the
                            United States of America and elsewhere. All rights reserved.
                        </Typography>
                    </Col>
                    <Col md={{ span: 4, offset: 4 }} sm={12}>
                        <Image style={{float: 'right'}} src="/images/rpl-footerlogo.png" fluid />
                    </Col>
                </Row>
                <Row style={{
                    justifyContent: 'center',
                    backgroundColor: '#17141f',}}>
                    <Link href="/Terms">Terms of Service</Link> &nbsp; | &nbsp; <Link href="/SiteRules">Site Rules</Link>
                </Row>
            </Container>
        );
    }
}

export default Footer;
