//External
import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import Button from "@material-ui/core/es/Button/Button";
import { HashLink as Link } from 'react-router-hash-link';
import 'bootstrap/dist/css/bootstrap.min.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";

class Header extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Container fluid style={{backgroundColor: 'rgba(48, 39, 77)'}}>
            <Navbar collapseOnSelect expand="lg" variant="dark" style={{fontSize: '1.250em', fontFamily: "DharmaGothicE-RegularItalic", backgroundColor: 'rgba(48, 39, 77, .95)'}}>
                <Navbar.Brand href="home"><img src={require("../Assets/images/roleplace-nav-logo.svg")}></img></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link ><Link to="/Home">Home</Link></Nav.Link>
                        <Nav.Link ><Link to="Home#Features" scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>Features</Link></Nav.Link>
                        <Nav.Link ><Link to="Home#ComingSoon" scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>Coming Soon</Link></Nav.Link>
                        <Nav.Link ><Link to="Home#Staff" scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}> Staff</Link></Nav.Link>
                        <Nav.Link ><Link to="Home#FAQ" scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>FAQ</Link></Nav.Link>
                        <Nav.Link ><Link to="/Register">Register</Link></Nav.Link>
                        <Nav.Link ><Link to="/RecoverRequest">Password Recovery</Link></Nav.Link>
                    </Nav>
                    <Nav>
                        <Button color="primary"
                                variant="contained"
                                style={{margin: '0em', color: "White"}} href="https://chat.roleplace.com">
                            Login
                        </Button>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            </Container>
        );
    }
}

export default Header;