//External
import React, { Component } from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Typography from "@material-ui/core/es/Typography";
import {colors} from "../Styles/colors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCodeBranch} from "@fortawesome/free-solid-svg-icons/faCodeBranch";
import Col from "react-bootstrap/Col";
import {faCalendarPlus} from "@fortawesome/free-solid-svg-icons/faCalendarPlus";
import {faComments} from "@fortawesome/free-solid-svg-icons/faComments";
import {faFileCode} from "@fortawesome/free-solid-svg-icons/faFileCode";
import {faTrophy} from "@fortawesome/free-solid-svg-icons/faTrophy";
import {faDiceD20} from "@fortawesome/free-solid-svg-icons/faDiceD20";
import {faCompressArrowsAlt} from "@fortawesome/free-solid-svg-icons/faCompressArrowsAlt";
import {faIdCard} from "@fortawesome/free-solid-svg-icons/faIdCard";
import {faEnvelopeOpenText} from "@fortawesome/free-solid-svg-icons/faEnvelopeOpenText";
import Footer from "./Footer";
import {faSignInAlt} from "@fortawesome/free-solid-svg-icons/faSignInAlt";
import Button from "@material-ui/core/es/Button";

class Spotlight extends Component {
    render() {
        return (
            <Container id={"ComingSoon"} fluid
                       style={{
                           backgroundImage: 'url(/images/rplace-bg2.png)',
                           height:'auto', width:'100%',
                           backgroundSize: 'cover',
                           backgroundRepeat: 'no-repeat',
                           backgroundColor: '#3A1557',
                           padding: '3rem 0 5rem 0',
                           overflowX: 'hidden'}}>
                {/* Stack the columns on mobile by making one full-width and the other half-width */}
                <Container>
                    <Row>
                        <Col>
                            <Typography variant={"h2"} style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                CONFIRMED! PASSWORD CHANGED!
                            </Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Typography variant="h3" style={{textAlign: 'center', width: '100%', color: colors.mainWhite}}>
                                So! What's next?<br/>
                                ...
                            </Typography>
                        </Col>
                    </Row>
                </Container>
                <Container style={{padding: '5rem 0 5rem 0'}}>
                    <Row>
                        <Typography variant="body2" style={{color:"#FFD27B", textAlign: 'center', width: '100%'}}>
                            <FontAwesomeIcon icon={faSignInAlt} size="4x"/>
                        </Typography>
                    </Row>
                    <Row>
                        <Col>
                            <Typography variant={"h2"} style={{color:"#FFD27B", textAlign: 'center', width: '100%'}}>
                                SEE YOU IN CYBERSPACE, ROLEPLACER.
                            </Typography>
                        </Col>
                    </Row>
                    <Row style={{display: 'flex', justifyContent: 'center', padding: '0 0 0 0'}}>
                        <Button color="primary" variant="contained"
                                style={{margin: '0 0.75em', color: "White"}} href="https://chat.roleplace.com">
                            LOGIN HERE
                        </Button>
                    </Row>
                </Container>
                <Row noGutters style={{width: '100%', marginBottom: '-5em'}}>
                    <Footer/>
                </Row>
            </Container>
        );
    }
}

export default Spotlight;
