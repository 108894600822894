import React from "react";

class ResponsiveImage extends React.Component {
    constructor(props) {
        super(props);

        this.styles = {
            height: this.props.height ? this.props.height : "100%",
            width: this.props.width ? this.props.width : "100%",
            overflow: "hidden",
            objectFit: "cover",
            objectPosition: "center center"
        };
    }

    render() {
        return (
            <img src={this.props.imgSrc ?
                this.props.imgSrc : require("../../Assets/images/roleplace-nav-logo.svg")}
                 style={this.styles}
                 className={`${this.props.class} flexDisplay flexCenter`}
                 alt={this.props.alt}
            />
        );
    }

    componentDidMount() {
    }
}

export default ResponsiveImage;
