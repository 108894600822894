//External
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import Button from "@material-ui/core/es/Button/Button";
import Typography from "@material-ui/core/es/Typography";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Jumbotron from "react-bootstrap/Jumbotron";
import {colors} from "../Styles/colors";
import Image from "react-bootstrap/Image";


class Hero extends Component {
    constructor(props) {
        super(props);
    }

    onButtonClick = () => {
        this.props.history.replace('/Prereg');
    }

    render() {

        return (
            <Jumbotron fluid style={{
                fontFamily: "DharmaGothicE-RegularItalic",
                backgroundColor: '#3A1557',
                marginBottom: '0',
                padding: '10rem 0 10rem 0',
                backgroundImage: 'url(/images/rplace-bg3.png)',
                backgroundSize: 'cover',
                backgroundPosition: 'left',
                backgroundRepeat: 'no-repeat'}}>
                <Container>
                    {/* Stack the columns on mobile by making one full-width and the other half-width */}
                    <Row>
                        <Col xs={12} md={8} xl={6} style={{margin: '0 0 1rem 0'}}>
                            <Typography variant={"h2"} style={{color: colors.mainWhite, width: '100%'}}>
                                TEXT-BASED, FREESTYLE WRITING
                                WITH AN AWESOME COMMUNITY.
                                <br/>
                                ROLEPLAY. MADE. EASY.
                            </Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={8} xl={6} style={{margin: '0 0 1rem 0'}}>
                            <Typography variant="body1">
                                Feature-rich and free forever, Roleplace is about giving the
                                tools creative RPers need to make characters, worlds,
                                and dynamic stories.
                            </Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={8}>
                            <Button color="primary"
                                    variant="contained"
                                    style={{ maxWidth: '20em' , color: "White"}}
                                    href="/Register"
                            >
                                Register!
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
        );
    }
}

export default Hero;
