//External
import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from "@material-ui/core/es/Button/Button";
import Typography from "@material-ui/core/es/Typography";
import { colors } from "../Styles/colors"
import { Redirect } from 'react-router-dom'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import {HashLink as Link} from "react-router-hash-link";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDiscord, faPatreon, faSteam, faTwitch} from "@fortawesome/free-brands-svg-icons";
import {faUsers} from "@fortawesome/free-solid-svg-icons/faUsers";
import {faCalendarPlus} from "@fortawesome/free-solid-svg-icons/faCalendarPlus";
import {faUserPlus} from "@fortawesome/free-solid-svg-icons/faUserPlus";
import {faLayerGroup} from "@fortawesome/free-solid-svg-icons/faLayerGroup";
import {faPalette} from "@fortawesome/free-solid-svg-icons/faPalette";
import {faPortrait} from "@fortawesome/free-solid-svg-icons/faPortrait";
import {faCode} from "@fortawesome/free-solid-svg-icons/faCode";
import {faListOl} from "@fortawesome/free-solid-svg-icons/faListOl";
import {faTh} from "@fortawesome/free-solid-svg-icons/faTh";
import {faUserShield} from "@fortawesome/free-solid-svg-icons/faUserShield";
import {faImage} from "@fortawesome/free-solid-svg-icons/faImage";
import {faTerminal} from "@fortawesome/free-solid-svg-icons/faTerminal";
import {faCodeBranch} from "@fortawesome/free-solid-svg-icons/faCodeBranch";
import {faDiceD20} from "@fortawesome/free-solid-svg-icons/faDiceD20";
import {faTrophy} from "@fortawesome/free-solid-svg-icons/faTrophy";
import {faComments} from "@fortawesome/free-solid-svg-icons/faComments";
import {faCompressArrowsAlt} from "@fortawesome/free-solid-svg-icons/faCompressArrowsAlt";
import {faFileCode} from "@fortawesome/free-solid-svg-icons/faFileCode";
import {faIdCard} from "@fortawesome/free-solid-svg-icons/faIdCard";

class ComingSoon extends Component {
    renderRedirect = (link) => {
        return <Redirect to='link'/>

    }
    render() {
        return (
            <Container id={"ComingSoon"} fluid style={{backgroundImage: 'url(/images/pennyconsole2.png)', height:'auto', width:'100%', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundColor: '#3A1557', padding: '3rem 0 5rem 0', overflowX: 'hidden'}}>
                {/* Stack the columns on mobile by making one full-width and the other half-width */}
                <Container>
                    <Row>
                        <Typography variant="body2" style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                            <FontAwesomeIcon icon={faCodeBranch} size="4x"/>
                        </Typography>
                    </Row>
                    <Row>
                        <Col>
                            <Typography variant={"h2"} style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                FOR THE FUTURE
                            </Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Typography variant="h3" style={{textAlign: 'center', width: '100%', color: colors.mainWhite}}>
                                We won't use the word Roadmap though. There's about 76 reasons why.
                            </Typography>
                        </Col>
                    </Row>
                    {/*<Row>*/}
                    {/*    <Col>*/}
                    {/*        <Image src="/images/transp-1.png" fluid />*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                </Container>
                <Container style={{padding: '5rem 0 0 0'}}>
                    <Row>
                        <Typography variant="body2" style={{color:"#FFD27B", textAlign: 'center', width: '100%'}}>
                            <FontAwesomeIcon icon={faCalendarPlus} size="4x"/>
                        </Typography>
                    </Row>
                    <Row>
                        <Col>
                            <Typography variant={"h2"} style={{color:"#FFD27B", textAlign: 'center', width: '100%'}}>
                                PLANNED FEATURES
                            </Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Typography variant="h3" style={{color: colors.mainWhite, textAlign: 'center', width: '100%'}}>
                                Profile Templates, Custom Themes, Rich-Text Editor and Many.. Many More!
                            </Typography>
                            <br/>
                        </Col>
                    </Row>
                    {/*Planned Features*/}
                    <Row>
                        <Col lg>
                            <Typography variant="body2" style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                <FontAwesomeIcon icon={faComments} size="2x"/>
                            </Typography>
                            <Typography variant={"h3"} style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                Rich Text Editor
                            </Typography>
                            <Typography variant="body1" style={{ textAlign: 'center', width: '100%'}}>
                                Bold, italic, strike-though? Give your posts a bit of expression when roleplaying,
                                or use formatting to help with profile editing and emphasizing certain walls of text!
                                All the options with none of the HTML.
                            </Typography>
                        </Col>
                        <Col lg>
                            <Typography variant="body2" style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                <FontAwesomeIcon icon={faFileCode} size="2x"/>
                            </Typography>
                            <Typography variant={"h3"} style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                Penny's Dev Lab
                            </Typography>
                            <Typography variant="body1" style={{ textAlign: 'center', width: '100%'}}>
                                Have some phenomenal codes to share with the world? With Penny's Coding lab you can submit
                                codes templates to be used and adored by the masses in your own personal compendium.
                            </Typography>
                        </Col>
                        <Col lg>
                            <Typography variant="body2" style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                <FontAwesomeIcon icon={faTrophy} size="2x"/>
                            </Typography>
                            <Typography variant={"h3"} style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                Persona Inventory & Badges
                            </Typography>
                            <Typography variant="body1" style={{ textAlign: 'center', width: '100%'}}>
                                Earn badges and stockpile them into your account stash, place them into your persona's
                                individual inventories, or equip one next to your username for the world to see. Purely
                                cosmetic.
                            </Typography>
                        </Col>
                    </Row>
                    <Row style={{padding: '1.5rem 0 0 0'}}>
                        {/*<Col lg>*/}
                        {/*    <Typography variant="body2" style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>*/}
                        {/*        <FontAwesomeIcon icon={faDiceD20} size="2x"/>*/}
                        {/*    </Typography>*/}
                        {/*    <Typography variant={"h3"} style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>*/}
                        {/*        Dicerolls & RNGs*/}
                        {/*    </Typography>*/}
                        {/*    <Typography variant="body1" style={{ textAlign: 'center', width: '100%'}}>*/}
                        {/*        Digital dice don't collect dust. But they do have the convenience of being summoned at will*/}
                        {/*        without a carrying pouch. D4, D6, D20, (possibly more) will be at your disposal to liven up RP.*/}
                        {/*    </Typography>*/}
                        {/*</Col>*/}
                        <Col lg>
                            <Typography variant="body2" style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                <FontAwesomeIcon icon={faCompressArrowsAlt} size="2x"/>
                            </Typography>
                            <Typography variant={"h3"} style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                Collapse & expand posts
                            </Typography>
                            <Typography variant="body1" style={{ textAlign: 'center', width: '100%'}}>
                                We've all been there. Everyone's doing their thing in a room and you have a 12 para entrance at
                                the ready. Now posts will have a max cap with the ability to expand for those who want to read
                                all of it!
                            </Typography>
                        </Col>
                        <Col lg>
                            <Typography variant="body2" style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                <FontAwesomeIcon icon={faIdCard} size="2x"/>
                            </Typography>
                            <Typography variant={"h3"} style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                Persona cards
                            </Typography>
                            <Typography variant="body1" style={{ textAlign: 'center', width: '100%'}}>
                                Add a custom background image, set your status, add badges to your inventory and BAM. It's
                                like a bad-ass business card for your persona. Except, y'know. RP-related. No judgement!
                            </Typography>
                        </Col>
                    </Row>
                </Container>
            </Container>
        );
    }
}

export default ComingSoon;
