export const IsValidEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const ApiErrorCodes = {
    CAPTCHA_FAIL: 403,
    EMAIL_ALREADY_REGISTERED: 409,
    SERVER_ERROR: 503,
    UNAUTHORIZED_ACTION: 401
};

export const RegistrationStateFields = {
    email: "email",
    password: "password",
    passwordConfirm: "passwordConfirm",
    rulesCheckBox: "rulesCheckBox",
    ageCheckBox: "ageCheckBox"
};

export const GetError = (errorDescription, apiErrorCode = null) => {
    return {
        hasError: true,
        apiErrorCode: apiErrorCode,
        errorDescription: errorDescription
    };
}