//External
import React, {Component} from 'react';
import AboutUs from './AboutUs'
import Staff from './Staff'
import Contribute from './Contribute'
import FAQ from './FAQ'
import Hero from './Hero';
import Footer from './Footer';
import Features from './Features';
import ComingSoon from "./ComingSoon";

class MainContent extends Component {
    constructor(props) {
        super(props);
    }

    componentDidUpdate() {
        if (!this.props.location.hash) {
            this.scrollToTop();
        }
    }

    scrollToTop = () => {
        if (this.topRef) {
            this.topRef.scrollIntoView({block: "end", behavior: "smooth"});
        }
    }

    render() {
        return (
            <div className="MainContentContainer">
                <div ref={el => {
                    this.topRef = el;
                }}>
                </div>
                <Hero/>
                <AboutUs/>
                <Features/>
                <ComingSoon/>
                <Contribute/>
                <Staff/>
                <FAQ/>
                <Footer/>
            </div>
        );
    }
}

export default MainContent;
