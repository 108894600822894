//External
import React, { Component } from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { colors } from "../Styles/colors"
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons/faQuestionCircle";
import {faUsers} from "@fortawesome/free-solid-svg-icons/faUsers";

class FAQ extends Component {
  render() {
    return (
        <Container id={"FAQ"} fluid style={{
            fontFamily: "DharmaGothicE-RegularItalic",
            backgroundColor: '#3A1557',
            paddingTop: '7rem',
            backgroundImage: 'url(/images/rplace-bg4.png)',
            backgroundSize: 'cover',
            backgroundPosition: 'left',
            backgroundRepeat: 'no-repeat'}}>
            <Row>
                <Typography variant="body2" style={{color: colors.mainWhite, textAlign: 'center', width: '100%'}}>
                    <FontAwesomeIcon icon={faQuestionCircle} size="4x"/>
                </Typography>
            </Row>
                <Row>
                    <Col>
                        <Typography variant={"h2"} style={{color: colors.mainWhite, textAlign: 'center', width: '100%'}}>
                            GOT QUESTIONS? WE GOT YOU. ;)
                        </Typography>
                    </Col>
                </Row>
            <Container fluid style={{
                padding: '1.5rem'}}>
            <Row>
                <Col>
                    <div>
                <ExpansionPanel>
                    <ExpansionPanelSummary>
                        <Typography className={""} variant={"h3"}>How do I Register my Character?</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Typography variant={"body2"}>
                            Head over to <Link href={"/Register"}>here</Link> and sign up!

                            From there, once you're logged in you can head over to the Personas Tab (<FontAwesomeIcon icon={faUsers}/>) and add in your Personas by name in the area at the bottom.
                            Currently  users have 15 Persona Slots available, but this will open up as we see more activity flourish on the site!
                        </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel>
                    <ExpansionPanelSummary>
                        <Typography className={""} variant={"h3"}>When Will the Site be Complete?</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Typography  variant={"body2"}>
                            We do not have a date for completion as of yet. We are currently in Public Beta phase - which means features are continually being worked on and added in, between being properly tested on our Alpha Branch.
                            Once we are happy with the amount of features, the overall experience, and long term stability of the site - we will make announcements and updates to keep you all up-to-date on all planned releases.
                        </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel>
                    <ExpansionPanelSummary>
                        <Typography className={""} variant={"h3"}>How Many Rooms Can I Create?</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Typography  variant={"body2"}>
                            As of right now, 2 Rooms can be created per account. We may look into making it possible for more in the future.
                        </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel>
                    <ExpansionPanelSummary>
                        <Typography className={""} variant={"h3"}>How Many Personas/Names Do I Get?</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Typography  variant={"body2"}>
                            At current, we have 15 Persona Slots available upon registration. This number will go up as we see activity on the site increase, to prevent name hoarding.
                            Those that earned names via our Patreon Rewards Program have had that number tallied and kept aside to be revisited and issued upon 1.0 Release of Roleplace. You have not been forgotten, don't worry~
                        </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel>
                    <ExpansionPanelSummary>
                        <Typography className={""} variant={"h3"}>How Long Can My Character Name be?</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Typography  variant={"body2"}>
                            Minimum - 2 Letters<br/>
                            Maximum - 28 Letters<br/>
                            <br/>
                            ☑ Numbers ☑ Symbols ☑ Spaces ☒ ASCII Characters
                        </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel>
                    <ExpansionPanelSummary>
                        <Typography className={""} variant={"h3"}>How Do Profiles work?</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Typography  variant={"body2"}>
                            Profiles can be coded using HTML/CSS/JS. Roleplace allows you to have a full page to represent each persona, that can be styled and designed to your own whims and desires as players.
                        </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel>
                    <ExpansionPanelSummary>
                        <Typography className={""} variant={"h3"}>I Don't Know How to Code! What Can I Do?</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Typography  variant={"body2"}>
                            Take a look at #free-profile-codes!! We have many amazing and generous coders who have posted templates for beginners that are easy to add, input your characters’ information, and save.

                            <br/>
                            Additionally, you can find lots of Resources online! Some of our favorites:
                            <br/><br/>
                            HTML/CSS Basics and good starting out help:<br/>
                            <Link href={"https://www.w3schools.com/"}>W3 Schools!</Link>
<br/>
                            Amazing effects for those a bit more familiar with the basics:<br/>
                            <Link href={"https://codepen.io/"}>CodePen!</Link>
                        </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel>
                    <ExpansionPanelSummary>
                        <Typography className={""} variant={"h3"}>How Many Characters Can Be Logged in Rooms?</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Typography  variant={"body2"}>
                            With one login - three characters, (separate or the same) can simultaneously exist in one room or multiple. Mix and match!
                        </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel>
                    <ExpansionPanelSummary>
                        <Typography className={""} variant={"h3"}>How Can I Support RolePlace?</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Typography  variant={"body2"}>
                            The first and most important way to support is word of mouth!
                            Have an RP group? Friends that share your hobby? Bring them over!<br/><br/>

                            If you’re looking for other ways, you could become a <Link href={"https://www.patreon.com/roleplace"}>Patron</Link> starting as low as $1! It keeps the lights on, the devs caffeinated, and you’ll get rewards to keep! Once there, head over to your `Account Settings` and the `Account Tab`, and you can link your Discord Account to your Patreon. That will get your Donator tags sorted out.

                            And with the added bonus of a comfy exchange for showing your RolePlace pride, we have a <Link href={"https://teespring.com/stores/roleplace?aid=marketplace"}>Merch Store</Link>!
                        </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel>
                    <ExpansionPanelSummary>
                        <Typography className={""} variant={"h3"}>Public Beta Details?</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Typography  variant={"body2"}>
                            With COVID19 effecting all of us, we have decided to open up Public Beta ahead of time. This means the site is available for anyone to use! FREE! Some of the main features currently available (as of time of writing) are:<br/>
                            <br/>
                             - Create Personas<br/>
                             - Create Rooms<br/>
                             - DMs & Notifications<br/>
                             - Dice Rolls, and /commands<br/>
                             - HTML/CSS/JS Profile Building<br/>
                             - Mod Tools ban/kick/mod<br/>
                        </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel>
                    <ExpansionPanelSummary>
                        <Typography className={""} variant={"h3"}>Alpha Testing Details?</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Typography  variant={"body2"}>
                            We've decided to shift our Alpha Tester program about a bit, and will be taking the time to refocus how we work things. Rather than it being an active tag on Discord, we will be removing it instead  for an opt-in access.
                            A few attractive qualities in an Alpha Tester:<br/>
                            <br/>
                            - Mac user, Safari user, or have access to those platforms.<br/>
                            - Profile Coders, or people familiar with HTML/CSS/JS or any other coding language!<br/>
                            - Some kind of coding genius capable of automatic testing?<br/>
                            - Heard about an announcement regarding something you're SUPER curious about getting your hands on, or have an particular insight about it that you want to make sure is heard?<br/>
                            <br/>
                            If one, many, or all of these sum you up in some way - please feel free to DM any Staff on our Discord and we will let you know when your Particular Set of Skills will be needed. (Being or contacting Liam Neeson is entirely optional.)
                        </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel>
                    <ExpansionPanelSummary>
                        <Typography className={""} variant={"h3"}>Global OOC Room?</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Typography  variant={"body2"}>
                            The OOC Arcade! It's your starting point if you're not quite ready to get into character yet.
                        </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel>
                    <ExpansionPanelSummary>
                        <Typography className={""} variant={"h3"}>Room Thumbnails and Backgrounds Dimensions?</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Typography  variant={"body2"}>
                            Room Avatar:<br/>
                            - Image will stretch to size if smaller-than.<br/>
                            - Image will align to top-right if larger than.<br/>
                            - Thumbnail size is 340px/340px<br/>
                            <br/>
                            Background Image:<br/>
                            - Image will stretch-to-size if smaller than.<br/>
                            - Image will shrink-to-size if larger than.<br/>
                            - Background will always  align to top-right.<br/>
                        </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                    </div>
                </Col>
            </Row>
            </Container>
        </Container>
    );
  }
}

export default FAQ;
