//External
import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/es/Button/Button";
import Typography from "@material-ui/core/es/Typography";
import {colors} from "../Styles/colors"
import {ApiErrorCodes, GetError, IsValidEmail, RegistrationStateFields} from "../Common";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "./Footer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignInAlt} from "@fortawesome/free-solid-svg-icons/faSignInAlt";

class Recover extends Component {
    constructor(props) {
        super(props);

        this.state = {
            complete: false,
            validEmail: true,
            email: this.props.email,
            touched: {
                email: false,
                password: false,
                passwordConfirm: false,
                key: false
            },
            error: {
                hasError: false,
                apiErrorCode: null,
                errorDescription: null,
            }
        }
        this.minimumPasswordLength = 8;
        this.baseURL = 'https://api.roleplace.com/webapi/register/passwordchange';
    }

    /********************************************** VALIDATROS ***************************************************/

    isValidForm = () => {
        return !this.invalidEmail() && !this.invalidPassword() & !this.invalidKey();
    }

    invalidEmail = () => {
        return this.state.touched.email && !IsValidEmail(this.state.email);
    }

    invalidPassword = () => {
        return this.state.touched.password &&
            (!this.state.password || this.state.password.length < this.minimumPasswordLength || !this.passwordsEquivalent());
    }

    invalidPasswordConfirm = () => {
        return this.state.touched.passwordConfirm &&
            (!this.state.passwordConfirm || this.state.passwordConfirm.length < this.minimumPasswordLength || !this.passwordsEquivalent())
    }

    passwordsEquivalent = () => {
        if (!this.state.touched.password || !this.state.touched.passwordConfirm) {
            return true;
        }
        return (this.state.password === this.state.passwordConfirm)
    }

    invalidKey = () => {
        return this.state.touched.key && !this.state.key
    }

    getEmailHelpText = () => {
        if (!this.state.touched.email) {
            return;
        }
        if (this.invalidEmail()) {
            return "Please enter a valid email."
        }
    }

    getPassWordHelpText = (passwordKey) => {
        if (!this.state.touched[passwordKey]) {
            return;
        }
        if (!this.state[passwordKey]) {
            return "Please enter password.";
        }
        if (this.state[passwordKey].length < this.minimumPasswordLength) {
            return "Passwords must be at least 8 characters.";
        }
        if (!this.passwordsEquivalent()) {
            return "Passwords must match.";
        }
    }

    /************************************************************************************************************/

    onSubmit = (e) => {
        e.preventDefault();
        if (!this.isValidForm()) {
            this.setState({
                touched: {
                    email: true,
                    password: true,
                    passwordConfirm: true,
                    key: true
                },
                error: GetError("Please complete the form.")
            });
            return;
        }
        this.options = {
            headers: {
                "Content-Type": 'application/json',
                "user": this.state.email,
                "auth": this.state.key,
                "pass": this.state.password
            },
            method: 'POST'
        }

        this.handleSubmit();
    }

    handleSubmit = () => {
        return fetch(`${this.baseURL}`, this.options)
            .then(this.checkStatus)
            .then(() => {
                this.setState({
                    complete: true
                });
            })
            .catch(response => {
            });
    }

    checkStatus = (response) => {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response;
        } else {
            if (response.status >= 400 && response.status < 500) {
                this.setState(state => ({
                    error: GetError("Ug oh! Something was wrong with the request." +
                        " Either the email or key is incorrect.", ApiErrorCodes.UNAUTHORIZED_ACTION)
                }));
            }
            if (response.status >= 500) {
                this.setState(state => ({
                    error: GetError("Ugh oh! Something is wrong on our end...", ApiErrorCodes.SERVER_ERROR)
                }));
            }
            throw new Error();
        }
    }

    // Handle local state
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    /**
     * Check For 'Dirty' Controls
     * @param field
     * @returns {Function}
     */
    handleBlur = (field) => (evt) => {
        this.setState({
            touched: {...this.state.touched, [field]: true},
        });
    }

    /********************************************** RENDER ***************************************************/

    render() {
        if (this.state.complete) {
            return (
                <Container id={"ComingSoon"} fluid
                           style={{
                               backgroundImage: 'url(/images/rplace-bg2.png)',
                               height:'auto', width:'100%',
                               backgroundSize: 'cover',
                               backgroundRepeat: 'no-repeat',
                               backgroundColor: '#3A1557',
                               padding: '3rem 0 5rem 0',
                               overflowX: 'hidden'}}>
                    {/* Stack the columns on mobile by making one full-width and the other half-width */}
                    <Container>
                        <Row>
                            <Col>
                                <Typography variant={"h2"} style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                    CONFIRMED! PASSWORD CHANGED!
                                </Typography>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Typography variant="h3" style={{textAlign: 'center', width: '100%', color: colors.mainWhite}}>
                                    So! What's next?<br/>
                                    ...
                                </Typography>
                            </Col>
                        </Row>
                    </Container>
                    <Container style={{padding: '5rem 0 5rem 0'}}>
                        <Row>
                            <Typography variant="body2" style={{color:"#FFD27B", textAlign: 'center', width: '100%'}}>
                                <FontAwesomeIcon icon={faSignInAlt} size="4x"/>
                            </Typography>
                        </Row>
                        <Row>
                            <Col>
                                <Typography variant={"h2"} style={{color:"#FFD27B", textAlign: 'center', width: '100%'}}>
                                    SEE YOU IN CYBERSPACE, ROLEPLACER.
                                </Typography>
                            </Col>
                        </Row>
                        <Row style={{display: 'flex', justifyContent: 'center', padding: '0 0 0 0'}}>
                            <Button color="primary" variant="contained"
                                    style={{margin: '0 0.75em', color: "White"}} href="https://chat.roleplace.com">
                                LOGIN HERE
                            </Button>
                        </Row>
                    </Container>
                    <Row noGutters style={{width: '100%', marginBottom: '-5em'}}>
                        <Footer/>
                    </Row>
                </Container>
            );
        } else {
            return (
                <Container className="MainContentContainer" fluid style={{
                backgroundColor: '#3A1557',
                    padding: '3rem 0 3rem 0',
                    backgroundImage: 'url(/images/rplace-bg2.png)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'left',
                    backgroundRepeat: 'no-repeat'}}>
                    <Container>
                        <Row style={{display: 'flex', justifyContent: 'center'}}>
                            <Typography variant={"h2"} style={{
                                color: colors.activeLinkColor,
                                textAlign: 'center',
                                width: '100%'
                            }}>
                                ALMOST THERE. PROMISE!
                            </Typography>
                            <Typography variant={"body1"} style={{textAlign: 'center', width: '100%'}}>
                                Please enter the required information for password reset. The key should have been delivered to your registration email.
                            </Typography>
                        </Row>
                    </Container>
                    <Container style={{display: 'flex', justifyContent: 'center', padding: '1rem 0 0 0'}}>>
                        <form onSubmit={this.onSubmit} className={"flexCenter flexDisplay flexColumn"}>
                            <Row>
                            <TextField
                                style={{margin: '1rem 0 0 0', width: '15em'}}
                                label="email"
                                autoComplete={"off"}
                                variant="outlined"
                                name={RegistrationStateFields.email}
                                placeholder="youremail@email.com"
                                onChange={this.handleChange}
                                error={this.invalidEmail()}
                                onBlur={this.handleBlur(RegistrationStateFields.email)}
                                helperText={this.getEmailHelpText()}
                                defaultValue={this.state.email}
                            />
                            </Row>
                            <Row>
                            <TextField
                                style={{margin: '1rem 0 0 0', width: '15em'}}
                                error={this.invalidKey()}
                                label="key"
                                autoComplete={"off"}
                                variant="outlined"
                                name="key"
                                placeholder="Enter your key"
                                onChange={this.handleChange}
                                onBlur={this.handleBlur('key')}
                                helperText={this.invalidKey() ? "required" : null}
                            />
                            </Row>
                            <Row>
                            <TextField
                                type="password"
                                style={{margin: '1rem 0 0 0', width: '15em'}}
                                error={this.invalidPassword()}
                                label="Password"
                                autoComplete={"off"}
                                variant="outlined"
                                name={RegistrationStateFields.password}
                                placeholder="Super Secret"
                                onChange={this.handleChange}
                                onBlur={this.handleBlur(RegistrationStateFields.password)}
                                helperText={this.getPassWordHelpText(RegistrationStateFields.password)}
                            />
                            </Row>
                            <Row>
                            <TextField
                                type="password"
                                style={{margin: '1rem 0 0 0', width: '15em'}}
                                error={this.invalidPasswordConfirm()}
                                label="Confirm Password"
                                autoComplete={"off"}
                                variant="outlined"
                                name={RegistrationStateFields.passwordConfirm}
                                placeholder="Super Secret"
                                onChange={this.handleChange}
                                onBlur={this.handleBlur(RegistrationStateFields.passwordConfirm)}
                                helperText={this.getPassWordHelpText(RegistrationStateFields.passwordConfirm)}
                            />
                            </Row>
                            {
                                this.state.error.hasError ?

                                    <Typography variant={"body1"} style={{color: 'red', textAlign: 'center'}}>
                                        {this.state.error.errorDescription}
                                    </Typography> : null
                            }
                            <Row style={{display: 'flex', justifyContent: 'center', padding: '1.5rem 0 1.5rem 0'}}>
                                <Button color="primary" variant="contained"
                                        style={{margin: '0 0.75em'}} type="submit">
                                    Change My Password!
                                </Button>
                            </Row>
                        </form>
                    </Container>
                    <Row noGutters style={{width: '100%', marginBottom: '-2em'}}>
                        <Footer/>
                    </Row>
                </Container>
            );
        }
    }
}

export default Recover;
