//External
import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from "@material-ui/core/es/Button/Button";
import Typography from "@material-ui/core/es/Typography";
import { colors } from "../Styles/colors"
import { Redirect } from 'react-router-dom'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarPlus} from "@fortawesome/free-solid-svg-icons/faCalendarPlus";
import {faCreativeCommonsNc} from "@fortawesome/free-brands-svg-icons/faCreativeCommonsNc";
import Col from "react-bootstrap/Col";

class Contribute extends Component {
  renderRedirect = (link) => {
    return <Redirect to='link'/>

  }
  render() {
    return (
        <Container id={"Staff"} fluid style={{
          fontFamily: "DharmaGothicE-RegularItalic",
          backgroundColor: 'rgb(58, 21, 87)',
            padding: '3rem 0 0 0'}}>
          <Container fluid>
              <Row>
                  <Typography variant="body2" style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                      <FontAwesomeIcon icon={faCreativeCommonsNc} size="4x"/>
                  </Typography>
              </Row>
              <Row>
                  <Col>
                      <Typography variant={"h2"} style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                          THIS AIN'T PAY TO WIN
                      </Typography>
                  </Col>
              </Row>
              <Row>
                  <Col>
                      <Typography variant="h3" style={{textAlign: 'center', width: '100%', color: colors.mainWhite}}>
                          Everything we do is made possible by your support. Best part? The paid stuff's totally optional.
                      </Typography>
                  </Col>
              </Row>
        <Row className="flexDisplay flexJustifyBetween StaffWrapper">
            <Card className="StaffCard" style={{margin: '1.5rem'}}>
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2" style={{fontFamily: "EpocaPro-Regular", fontSize: '2rem'}}>
                    Chat App
                  </Typography>
                  <Typography style={{fontFamily: "DharmaGothicE-RegularItalic", fontSize: '4rem', color: colors.activeLinkColor}}>
                    FREE
                  </Typography>
                  <Typography component="p" variant="body2">
                    (Currently in OPEN Beta) Register, create characters, roleplay, and meet new folks! Will ALWAYS be free to sign up and use, no strings attached!
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
        <Button size="large" color="primary" href="/Register">
          REGISTER HERE
        </Button>
      </CardActions>
            </Card>

            <Card className="StaffCard" style={{margin: '1.5rem'}}>
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2" style={{fontFamily: "EpocaPro-Regular", fontSize: '2rem'}}>
                    Discord
                  </Typography>
                  <Typography style={{fontFamily: "DharmaGothicE-RegularItalic", fontSize: '4rem', color: colors.activeLinkColor}}>
                    ALSO FREE
                  </Typography>
                  <Typography component="p" variant="body2">
                    Your presence is the best support of all. Join the community to get the most detailed updates and make friends along the way. We love meeting you all in VC.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
        <Button size="large" color="primary" target="_blank" href="https://discord.gg/nHp7XWF">
          COME CHAT
        </Button>
      </CardActions>
            </Card>

            <Card className="StaffCard" style={{margin: '1.5rem'}}>
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2" style={{fontFamily: "EpocaPro-Regular", fontSize: '2rem'}}>
                    Patreon
                  </Typography>
                  <Typography style={{fontFamily: "DharmaGothicE-RegularItalic", fontSize: '4rem', color: colors.activeLinkColor}}>
                    $1-25
                  </Typography>
                  <Typography component="p" variant="body2">
                    Being a patron nets you accruing perks that can be used to win art commissions, vote on fun features, and reserve names. Tiers are super flexible starting at just a dollar.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
        <Button size="large" color="primary" target="_blank" href="http://www.patreon.com/roleplace">
          BROWSE REWARDS
        </Button>
      </CardActions>
            </Card>

            <Card className="StaffCard" style={{margin: '1.5rem'}}>
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2" style={{fontFamily: "EpocaPro-Regular", fontSize: '2rem'}}>
                    Merchandise
                  </Typography>
                  <Typography style={{fontFamily: "DharmaGothicE-RegularItalic", fontSize: '4rem', color: colors.activeLinkColor}}>
                   $22-39
                  </Typography>
                  <Typography component="p" variant="body2">
                    Want to show your support with a one-time transaction and get something cool and tangible in return? Post your merch selfie on Discord for special roles!
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
        <Button size="large" color="primary" target="_blank" href="https://teespring.com/stores/roleplace">
          BROWSE THE STORE
        </Button>
      </CardActions>
            </Card>
          </Row>
          </Container>
        </Container>
    );
  }
}

export default Contribute;
