import React, {Component} from 'react';
import ResponsiveImage from "./Shared/ResponsiveImage";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Typography from "@material-ui/core/es/Typography";
import {colors} from "../Styles/colors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignInAlt} from "@fortawesome/free-solid-svg-icons/faSignInAlt";
import Button from "@material-ui/core/es/Button";
import Footer from "./Footer";
import {faEnvelopeOpenText} from "@fortawesome/free-solid-svg-icons/faEnvelopeOpenText";
import Link from "@material-ui/core/Link";
import {faPowerOff} from "@fortawesome/free-solid-svg-icons/faPowerOff";

const backgroundStyle = {
    backgroundImage: `url(${require('../Assets/images/comingsoon-bg.png')})`,
};
// This page should eventually take you to the login screen
class ThankYou extends Component {
    render() {
        return (
            <Container id={"ComingSoon"} fluid
                       style={{
                           backgroundImage: 'url(/images/rplace-bg2.png)',
                           height:'auto', width:'100%',
                           backgroundSize: 'cover',
                           backgroundRepeat: 'no-repeat',
                           backgroundColor: '#3A1557',
                           padding: '3rem 0 5rem 0',
                           overflowX: 'hidden'}}>
                {/* Stack the columns on mobile by making one full-width and the other half-width */}
                <Container>
                    <Row>
                        <Col>
                            <Typography variant={"h2"} style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                REGISTERED EMAIL CONFIRMED!
                            </Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Typography variant="h3" style={{textAlign: 'center', width: '100%', color: colors.mainWhite}}>
                                So! What's next?<br/>
                                ...
                            </Typography>
                        </Col>
                    </Row>
                </Container>
                <Container style={{padding: '5rem 0 5rem 0'}}>
                    <Row>
                        <Typography variant="body2" style={{color:"#FFD27B", textAlign: 'center', width: '100%'}}>
                            <FontAwesomeIcon icon={faPowerOff} size="4x"/>
                        </Typography>
                    </Row>
                    <Row>
                        <Col>
                            <Typography variant={"h2"} style={{color:"#FFD27B", textAlign: 'center', width: '100%'}}>
                               FIRSTLY, THANK YOU FOR REGISTERING WITH US.
                            </Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Typography variant="h3" style={{color: colors.mainWhite, textAlign: 'center', width: '100%'}}>
                                Some things to note below:
                            </Typography>
                            <br/>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg>
                            <Typography variant={"h3"} style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                For New Roleplacers:
                            </Typography>
                            <Typography variant="body1" style={{ textAlign: 'center', width: '100%'}}>
                                <Link href={"https://discord.gg/nHp7XWF"} target="_blank" >Click here</Link> to join our Discord server and stay up to date on when OPEN BETA will be available
                                (Spoiler alert: Sooner than you think!) OR <Link href={"http://www.patreon.com/roleplace"} target="_blank" >click here</Link>, if you're feeling generous, to become
                                a Patron! Beta Access is only $1.
                            </Typography>
                        </Col>
                        <Col lg>
                            <Typography variant={"h3"} style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                For Supporters:
                            </Typography>
                            <Typography variant="body1" style={{ textAlign: 'center', width: '100%'}}>
                                If you're currently a supporter via Patreon, or have purchased RPL Merch in the past, you
                                can log in <Link href={"https://chat.roleplace.com/"} target="_blank" >here</Link>. If you come across any issues, please feel free to contact Marley on our
                                <Link href={"https://discord.gg/nHp7XWF"} target="_blank" > Official Discord</Link> server.
                            </Typography>
                        </Col>
                    </Row>
                </Container>
                <Row noGutters style={{width: '100%', marginBottom: '-10em'}}>
                    <Footer/>
                </Row>
            </Container>
        );
    }
}
export default ThankYou