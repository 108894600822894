//External
import React, {Component} from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/es/Button/Button";
import Typography from "@material-ui/core/es/Typography";
import {colors} from "../Styles/colors"
import Recover from './Recover';
import {GetError, IsValidEmail} from "../Common";
import ResponsiveImage from "./Shared/ResponsiveImage";
import Container from "react-bootstrap/Container";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Footer from "./Footer";

// Constants
const recaptchaRef = React.createRef();

class RecoverRequest extends Component {
    constructor(props) {
        super(props);

        this.state = {
            complete: false,
            validEmail: true,
            touched: {
                email: false
            },
            error: {
                hasError: false,
                errorDescription: null,
            }
        }
        this.baseURL = 'https://api.roleplace.com/webapi/register/requestpasswordchange';
    }

    /********************************************** VALIDATROS ***************************************************/

    isValidForm = () => {
        const recaptchaValue = recaptchaRef.current && recaptchaRef.current.getValue();
        return recaptchaValue && !this.invalidEmail();
    }

    invalidEmail = () => {
        return this.state.touched.email && !IsValidEmail(this.state.email);
    }

    onSubmit = (e) => {
        e.preventDefault();

        if (!this.isValidForm()) {
            this.setState(state => ({
                touched: {
                    email: true
                },
                error: GetError("Please complete the form.")
            }));
            return;
        }

        this.options = {
            headers: {
                "Content-Type": 'application/json',
                "user": this.state.email,
                "recaptcha": recaptchaRef.current.getValue()
            },
            method: 'POST'
        }

        this.handleSubmit();
    }

    handleSubmit = () => {
        return fetch(`${this.baseURL}`, this.options)
            .then(this.checkStatus)
            .then(() => {
                this.setState({complete: true});
            })
            .catch(response => {
            });
    }

    checkStatus = (response) => {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response;
        } else {
            if (response.status >= 400 && response.status < 500) {
                this.setState(state => {
                    error: GetError("Ugh oh! Something was wrong with the request")
                });
            }
            if (response.status >= 500) {
                this.setState(state => {
                    error: GetError("Ugh oh! Something is wrong on our end...")
                });
            }
            throw new Error();
        }
    }

// Handle local state
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    /**
     * Check For 'Dirty' Controls
     * @param field
     * @returns {Function}
     */
    handleBlur = (field) => (evt) => {
        this.setState({
            touched: {...this.state.touched, [field]: true},
        });
    }

    getEmailHelpText = () => {
        if (!this.state.touched.email) {
            return;
        }
        if (this.invalidEmail()) {
            return "Please enter a valid email."
        }
    }

    onCaptchaError = () => {
        this.setState(state => ({
            error: GetError("Woops! Looks like something is wrong with the captcha. Please" +
                " refresh the page.")
        }));
    }

    /********************************************** RENDER ***************************************************/

    render() {
        if (this.state.complete) {
            return (
                <Recover email={this.state.email}/>
            );
        } else {
            return (
                <Container className="MainContentContainer" fluid style={{
                    backgroundColor: '#3A1557',
                    padding: '3rem 0 3rem 0',
                    backgroundImage: 'url(/images/rplace-bg2.png)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'left',
                    backgroundRepeat: 'no-repeat'}}>
                    <Container>
                    <Row style={{display: 'flex', justifyContent: 'center'}}>
                            <Typography variant={"h2"} style={{
                                color: colors.activeLinkColor,
                                textAlign: 'center',
                                width: '100%'
                            }}>
                                HAVING PROBLEMS?
                            </Typography>
                           <Typography variant={"body1"} style={{textAlign: 'center', width: '100%'}}>
                            Please enter the required information for password reset. The key should have been delivered to your registration email.
                            </Typography>

                    </Row>
                        <Row style={{display: 'flex', justifyContent: 'center'}}>
                        <Image src="/images/PennyChibi2.png" width={"15%"} fluid />
                        </Row>
                    </Container>
                    <Container style={{display: 'flex', justifyContent: 'center', padding: '1rem 0 0 0'}}>
                        <form onSubmit={this.onSubmit}>
                            <Row>
                            <TextField
                                label="email"
                                autoComplete={"off"}
                                variant="outlined"
                                name="email"
                                placeholder="youremail@email.com"
                                onChange={this.handleChange}
                                error={this.invalidEmail()}
                                onBlur={this.handleBlur('email')}
                                helperText={this.getEmailHelpText()}
                                onErrored={this.onCaptchaError}
                                style={{width: '304px'}} // I hate this but this is the width of the captcha
                            />
                            </Row>
                            <Row style={{display: 'flex', justifyContent: 'center', padding: '2rem 0 0 0'}}>
                                <ReCAPTCHA
                                    sitekey="6LcHOjYUAAAAANnlJK1BsO73AAZiI2FtUiuZqqzx"
                                    theme="dark"
                                    ref={recaptchaRef}
                                />
                            </Row>
                            {
                                this.state.error.hasError ?
                                    <Typography variant={"body1"} style={{color: 'red', textAlign: 'center'}}>
                                        {this.state.error.errorDescription}
                                    </Typography> : null
                            }
                            <Row style={{display: 'flex', justifyContent: 'center', padding: '1.5rem 0 1.5rem 0'}}>
                                <Button color="primary" variant="contained"
                                        style={{margin: '0'}} type="submit">
                                    Recover Password
                                </Button>
                            </Row>
                        </form>
                    </Container>
                    <Row noGutters style={{width: '100%', marginBottom: '-2em'}}>
                        <Footer/>
                    </Row>
                </Container>
            );
        }
    }
}

export default RecoverRequest;
