//External
import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from "@material-ui/core/es/Button/Button";
import Typography from "@material-ui/core/es/Typography";
import { colors } from "../Styles/colors"
import { Redirect } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import {HashLink as Link} from "react-router-hash-link";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDiscord, faPatreon, faSteam, faTwitch} from "@fortawesome/free-brands-svg-icons";
import {faUsers} from "@fortawesome/free-solid-svg-icons/faUsers";
import Modal from "react-bootstrap/Modal";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {faUserAstronaut} from "@fortawesome/free-solid-svg-icons/faUserAstronaut";
import {faTh} from "@fortawesome/free-solid-svg-icons/faTh";
import {faUserShield} from "@fortawesome/free-solid-svg-icons/faUserShield";
import {faImage} from "@fortawesome/free-solid-svg-icons/faImage";
import {faTerminal} from "@fortawesome/free-solid-svg-icons/faTerminal";
import {faUserPlus} from "@fortawesome/free-solid-svg-icons/faUserPlus";
import {faLayerGroup} from "@fortawesome/free-solid-svg-icons/faLayerGroup";
import {faPalette} from "@fortawesome/free-solid-svg-icons/faPalette";
import {faPortrait} from "@fortawesome/free-solid-svg-icons/faPortrait";
import {faCode} from "@fortawesome/free-solid-svg-icons/faCode";
import {faListOl} from "@fortawesome/free-solid-svg-icons/faListOl";
import {faSkull} from "@fortawesome/free-solid-svg-icons/faSkull";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {faDiceD20} from "@fortawesome/free-solid-svg-icons/faDiceD20";
import {faMagic} from "@fortawesome/free-solid-svg-icons/faMagic";


function MyVerticallyCenteredModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
            style={{
                fontFamily: "DharmaGothicE-RegularItalic"
            }}
            className="UpdateModal"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" style={{fontSize: "2rem", color:"#FFD27B"}}>
                    V3.1 & V3.0 : Dice Rolls, Direct Messages, Commands
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Typography variant="body2" style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                            <FontAwesomeIcon icon={faMagic} size="4x"/>
                        </Typography>
                    </Row>
                    <Row>
                        <Col>
                            <Typography variant={"h2"} style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                V3.1.0-B : Performance Adjustment, MAGIC8
                            </Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Typography variant={"h3"} style={{color: colors.activeLinkColor, width: '100%'}}>
                                <br/>
                                HOTFIXES
                            </Typography>
                            <Typography variant="body1" style={{color: colors.mainWhite, width: '100%'}}>
                                <ul>
                                    <li>
                                        Maintenance / enhancements for performance.
                                    </li><br/>
                                    <li>
                                        A new /command has also snuck in, give /magic8ball a try!
                                    </li><br/>
                                </ul>
                            </Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Typography variant="body2" style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                            <FontAwesomeIcon icon={faDiceD20} size="4x"/>
                        </Typography>
                    </Row>
                    <Row>
                        <Col>
                            <Typography variant={"h2"} style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                V3.1.0 : Dicerolling, Bugfixes, Performance!
                            </Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Typography variant={"h3"} style={{color: colors.activeLinkColor, width: '100%'}}>
                                <br/>
                                We present a number of quality-of-life improvements for all Roleplacers!
                            </Typography>
                            <Typography variant="body1" style={{color: colors.mainWhite, width: '100%'}}>
                                <ul>
                                    <li>
                                        Introducing Dice rolls to RPL! Addition (+), subtraction (-), multiplication (*), and division (/) work (e.g: /roll 1d20*10+5).
                                    </li><br/>
                                    <li>
                                        Fixed the dreaded, the horrible, the fearsome 'laggy textbox' issue. You shall no longer live in fear of very large posts in active chats!
                                    </li><br/>
                                    <li>
                                        Room tabs will now save your unfinished posts! Yes!
                                    </li><br/>
                                    <li>
                                        Fixed the ghosts in the DM notifications. A issue where users would receive random notifications upon login.
                                    </li><br/>
                                    <li>
                                        Switching between DMs and Room views will now return you right where you were before. Right where you belong!
                                    </li><br/>
                                    <li>
                                        Added logic to clear the DM histories of deleted personas.
                                    </li><br/>
                                    <li>
                                        And countless other fixes!
                                    </li><br/>
                                </ul>
                            </Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Typography variant="body2" style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                            <FontAwesomeIcon icon={faEnvelope} size="4x"/>
                        </Typography>
                    </Row>
                    <Row>
                        <Col>
                            <Typography variant={"h2"} style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                V3.0.0 : Direct Messages (and other) Enhancements
                            </Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Typography variant={"h3"} style={{color: colors.activeLinkColor, width: '100%'}}>
                                <br/>
                                QUALITY-OF-LIFE enhancements added:
                            </Typography>
                            <Typography variant="body1" style={{color: colors.mainWhite, width: '100%'}}>
                                <ul>
                                    <li>
                                        See DMs<a target="_blank" href={"https://youtu.be/sNl1XL3VVek"}> in action!</a>
                                    </li><br/>
                                    <li>
                                        Initiate DMs by using the DM button on the userlist!
                                    </li><br/>
                                    <li>
                                        DM notifications. Get notified on your Direct Messages!
                                    </li><br/>
                                    <li>
                                        Easily switch between your personas and respond to others from a single location.
                                    </li><br/>
                                    <li>
                                        Added ‘View My profile’ as an action to persona cards
                                    </li><br/>
                                    <li>
                                        Added an HTML template option to the profile text input. For users that are used to pasting
                                        their profile code as separate sections, this should help provide a visual indicator of where
                                        to paste each section. This is a (hopefully) helpful measure until we get a chance to really
                                        jazz up the profile process!
                                    </li><br/>
                                    <li>
                                        Small misc enhancements to persona edit to make it friendlier, like more help text.
                                    </li><br/>
                                    <li>
                                        New Persona cards on the user list!
                                    </li><br/>
                                </ul>
                            </Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Typography variant="body2" style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                            <FontAwesomeIcon icon={faUserAstronaut} size="4x"/>
                        </Typography>
                    </Row>
                    <Row>
                        <Col>
                            <Typography variant={"h2"} style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                V2.1.0 : Room Mod (and other) Enhancements
                            </Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Typography variant={"h3"} style={{color: colors.activeLinkColor, width: '100%'}}>
                                <br/>
                                QUALITY-OF-LIFE enhancements added:
                            </Typography>
                            <Typography variant="body1" style={{color: colors.mainWhite, width: '100%'}}>
                                <ul>
                                    <li>
                                        When a user is granted mod rights or has them revoked, they will see their "admin actions" options appear or disappear immediately, rather than having to force a page refresh.
                                    </li><br/>
                                    <li>
                                        Room ban functionality has been polished. Users who have been banned are cleanly removed on their end now.
                                    </li><br/>
                                    <li>
                                        A snackbar now appears to notify a user if they try to join a room with a persona that has been banned.
                                    </li><br/>
                                    <li>
                                        Improved Room Join popup text / title to be more descriptive.
                                    </li><br/>
                                    <li>
                                        Added "unban" functionality to room admin actions triple elipses
                                    </li><br/>
                                    <li>
                                        Added admin console help text as the permanent default first message in the admin console
                                    </li><br/>
                                    <li>
                                        Enhanced the 'My Personas' screen to include more descriptive help text on what a user is supposed to do.
                                    </li><br/>
                                </ul>
                            </Typography>
                            <Typography variant={"h3"} style={{color: colors.activeLinkColor, width: '100%'}}>
                                BUGFIXES introduced:
                            </Typography>
                            <Typography variant="body1" style={{color: colors.mainWhite, width: '100%'}}>
                                <ul>
                                    <li>
                                        Fixed a bug where a users avatar would not show correctly to other users in a room until they switched tabs/refreshed.
                                    </li><br/>
                                    <li>
                                        Exceeding the max room message character limit is now indicated via some more helpful 'red'.
                                    </li><br/>
                                    <li>
                                        Fixed a bug where the "room owner" in the "my rooms" tab was showing an incorrect persona.
                                    </li><br/>
                                </ul>
                            </Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Typography variant="body2" style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                            <FontAwesomeIcon icon={faSkull} size="4x"/>
                        </Typography>
                    </Row>
                    <Row>
                        <Col>
                            <Typography variant={"h2"} style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                V2.0.0 : Room Moderation, Room-Reaping, Readability
                            </Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Typography variant={"h3"} style={{color: colors.activeLinkColor, width: '100%'}}>
                                <br/>
                                We have a few quality-of-life enhancements and features to share with you today, Roleplacers!
                            </Typography>
                            <Typography variant="body1" style={{color: colors.mainWhite, width: '100%'}}>
                                <ul>
                                    <li>
                                        Many visibility/legibility/readability improvements on typography site-wide,
                                        from chat to buttons, as per that feedback you've been giving us!
                                    </li><br/>
                                    <li>
                                        <b> FEAR THE REAPER!! </b> The Room Reaper has been added to the fray, and he
                                        will do his rounds every 48 hours. When he cometh...
                                        <br/>
                                        Rooms which are inactive will be added to the Room Reaper's list. An <b>inactive</b>
                                        room is a room with nobody in it.
                                        <br/>
                                        Nothing happens during the first inactivity check. However, if by the time the
                                        reaper comes around AGAIN (after another 48 hours) and no one has since joined
                                        the room in that time, the room will be deleted.
                                    </li><br/>
                                    <li>
                                        Created moderation actions for rooms. (BAN, UNBAN, MODERATE, DEMODERATE). From a
                                        chatroom, room owners / mods may click the triple dots (ellipsis) on the top
                                        right of a message to perform admin actions.
                                    </li><br/>
                                    <li>
                                        Admin Console. Room mods / admins may open the admin console from their rooms
                                        via the new toggle button to the left of the chat input. This provides the same
                                        actions as clicking on a message, but from a command prompt. It also enables
                                        admins to view the "mod-logs" -- all mod level actions that have been executed
                                        within a room. For a full list of what you can do, just send a message with
                                        'help'
                                    </li><br/>
                                    <li>Other boring bug-fixes!</li>
                                </ul>
                            </Typography>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button color="primary"
                        variant="contained" onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

function FeatList() {
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <ButtonToolbar>
            <Button color="primary"
                    variant="contained" onClick={() => setModalShow(true)}>
                What's Different?
            </Button>

            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </ButtonToolbar>
    );
}

class Features extends Component {
    renderRedirect = (link) => {
        return <Redirect to='link'/>

    }



    render() {
        return (
            <Container id={"Features"} fluid style={{backgroundImage: 'url(/images/rplace-bg6.png)', height:'inherit', width:'100%', backgroundColor: '#3A1557', padding: '0 0 0 0', overflow: 'hidden'}}>
                <Image src="/images/topgrad.png" fluid style={{marginTop: '-0.4em'}}/>
                {/* Stack the columns on mobile by making one full-width and the other half-width */}
                <Container>
                    <Row>
                        <Typography variant="body2" style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                            <FontAwesomeIcon icon={faDiceD20} size="4x"/>
                        </Typography>
                    </Row>
                    <Row>
                        <Col>
                            <Typography variant={"h2"} style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                V3.1 : DICEROLLS, BUG-FIXES & PERFORMANCE!
                            </Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Typography variant="h3" style={{textAlign: 'center', color: colors.mainWhite, width: '100%'}}>
                                We present a number of quality-of-life improvements for all Roleplacers!
                            </Typography>
                        </Col>
                    </Row>
                    <Row >
                        <Col style={{display: 'flex', justifyContent: 'center', padding: '2rem 0 0 0'}}>
                            <FeatList />
                        </Col>
                    </Row>
                </Container>
                {/*Persona Features Container*/}
                <Container style={{padding: '5rem 0 0 0'}}>
                    <Row>
                        <Typography variant="body2" style={{color:"#FFD27B", textAlign: 'center', width: '100%'}}>
                            <FontAwesomeIcon icon={faUsers} size="4x"/>
                        </Typography>
                    </Row>
                    <Row>
                        <Col>
                            <Typography variant={"h2"} style={{color:"#FFD27B", textAlign: 'center', width: '100%'}}>
                                PERSONA FEATURES
                            </Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Typography variant="h3" style={{color: colors.mainWhite, textAlign: 'center', width: '100%'}}>
                                Avatars, profiles and multi-room joining - with tabs!
                            </Typography>
                            <br/>
                        </Col>
                    </Row>
                    {/*Persona Features*/}
                    <Row>
                        <Col lg>
                            <Typography variant="body2" style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                <FontAwesomeIcon icon={faUserPlus} size="2x"/>
                            </Typography>
                            <Typography variant={"h3"} style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                Seamless character creation
                            </Typography>
                            <Typography variant="body1" style={{ textAlign: 'center', width: '100%'}}>
                                Don't worry about signing up everytime to create new personas, just type a name and hit
                                create! Every character is tied to your e-mail. And the best part? No IP blocking
                                for shared sign-ins!
                            </Typography>
                        </Col>
                        <Col lg>
                            <Typography variant="body2" style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                <FontAwesomeIcon icon={faLayerGroup} size="2x"/>
                            </Typography>
                            <Typography variant={"h3"} style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                Join multiple rooms
                            </Typography>
                            <Typography variant="body1" style={{ textAlign: 'center', width: '100%'}}>
                                Currently you can have 3 active connections. So mix & match! One persona in three rooms,
                                three in one room, two in one and one in another, etc. The possibilities are up to you!
                            </Typography>
                        </Col>
                        <Col lg>
                            <Typography variant="body2" style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                <FontAwesomeIcon icon={faPalette} size="2x"/>
                            </Typography>
                            <Typography variant={"h3"} style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                Character overview
                            </Typography>
                            <Typography variant="body1" style={{ textAlign: 'center', width: '100%'}}>
                                Under a single account, all of your personas are visible to edit profiles and avatars, change colors,
                                see which rooms they're active in, all without logging into each one individually.

                            </Typography>
                        </Col>
                    </Row>
                    <Row style={{padding: '1.5rem 0 0 0'}}>
                        <Col lg>
                            <Typography variant="body2" style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                <FontAwesomeIcon icon={faPortrait} size="2x"/>
                            </Typography>
                            <Typography variant={"h3"} style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                Persona avatar
                            </Typography>
                            <Typography variant="body1" style={{ textAlign: 'center', width: '100%'}}>
                                What's a name in a userlist without an avatar nowadays? Pick an image, upload it to your favorite image hosting
                                site, then copy and paste the link. It'll keep your persona's name company.
                            </Typography>
                        </Col>
                        <Col lg>
                            <Typography variant="body2" style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                <FontAwesomeIcon icon={faCode} size="2x"/>
                            </Typography>
                            <Typography variant={"h3"} style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                Open-ended coding
                            </Typography>
                            <Typography variant="body1" style={{ textAlign: 'center', width: '100%'}}>
                                Customize your persona's profile page with powerful parameters. All profiles can be coded in the latest version of
                                HTML, CSS, and.. Javascript? What? That's right. Javascript. For free.
                            </Typography>
                        </Col>
                        <Col lg>
                            <Typography variant="body2" style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                <FontAwesomeIcon icon={faListOl} size="2x"/>
                            </Typography>
                            <Typography variant={"h3"} style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                Character slots
                            </Typography>
                            <Typography variant="body1" style={{ textAlign: 'center', width: '100%'}}>
                                Spiderman once said everyone gets one. We'll give you fifteen. And that's just for signing up. Word on the street is..
                                That's 50% more than the competition, and we're totally fine with that.
                            </Typography>
                        </Col>
                    </Row>
                </Container>
                {/*Room Features Container*/}
                <Container style={{padding: '5rem 0 0 0'}}>
                    <Row>
                        <Typography variant="body2" style={{color:"#FFD27B", textAlign: 'center', width: '100%'}}>
                            <FontAwesomeIcon icon={faTh} size="4x"/>
                        </Typography>
                    </Row>
                    <Row>
                        <Col lg>
                            <Typography variant={"h2"} style={{color:"#FFD27B", textAlign: 'center', width: '100%'}}>
                                ROOM FEATURES
                            </Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg>
                            <Typography variant="h3" style={{color: colors.mainWhite, textAlign: 'center', width: '100%'}}>
                               Make your rooms stand out more with built-in features.
                            </Typography>
                            <br/>
                        </Col>
                    </Row>
                    {/*Room Features*/}
                    <Row>
                        <Col lg>
                            <Typography variant="body2" style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                <FontAwesomeIcon icon={faUserShield} size="2x"/>
                            </Typography>
                            <Typography variant={"h3"} style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                Set your owner
                            </Typography>
                            <Typography variant="body1" style={{ textAlign: 'center', width: '100%'}}>
                                Gone are the days that your currently logged character has to be room owner.
                                Our room creator allows you to set any of the personas tied to your account as the owner of your room.
                            </Typography>
                        </Col>
                        <Col lg>
                            <Typography variant="body2" style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                <FontAwesomeIcon icon={faImage} size="2x"/>
                            </Typography>
                            <Typography variant={"h3"} style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                Images, description & tags
                            </Typography>
                            <Typography variant="body1" style={{ textAlign: 'center', width: '100%'}}>
                                Set a room avatar that stands out, write a catchy description on the spot and
                                add tags to let others know what kind of show you're running! You can even preview room backgrounds!
                            </Typography>
                        </Col>
                        <Col lg>
                            <Typography variant="body2" style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                <FontAwesomeIcon icon={faTerminal} size="2x"/>
                            </Typography>
                            <Typography variant={"h3"} style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                Moderation tools & console
                            </Typography>
                            <Typography variant="body1" style={{ textAlign: 'center', width: '100%'}}>
                                Mod, De-mod, kick and ban with a simple drop-down menu or Penny's Admin Console.
                                See who's been kicking who, or which users have been banned and de-modded at the press of a button!
                            </Typography>
                        </Col>
                    </Row>
                </Container>
                <Image src="/images/bottomgrad.png" fluid style={{marginBottom: '-0.4em'}}/>
            </Container>
        );
    }
}

export default Features;
