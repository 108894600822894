import React, { Component } from 'react';
import { BrowserRouter, HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import MuiThemeProvider from "@material-ui/core/es/styles/MuiThemeProvider";
import { createMuiTheme } from '@material-ui/core/styles';

// ours
import Header from './Components/Header';
import Registration from './Components/Registration';
import Spotlight from './Components/Spotlight';
import MainContent from './Components/MainContent';

import { colors } from "./Styles/colors";

class App extends Component {

  constructor(props) {
    super(props);
  }

  state = {
    konamiCodeActive: false
  };

  componentDidMount() {
    this.onKonamiCode(this.activateCheats);
  }

  onKonamiCode(callBack) {
    var input = '';
    var key = '38384040373937396665';
    document.addEventListener('keydown', function(e) {
      input += ("" + e.keyCode);
      if (input === key) {
        input = '';
        return callBack();
      }
      if (!key.indexOf(input)) return;
      input = ("" + e.keyCode);
    });
  }

  activateCheats = () => {
    this.setState({ konamiCodeActive: !this.state.konamiCodeActive });
    if (this.state.konamiCodeActive) {
      document.body.classList.add('invert');
    }
    else {
      document.body.classList.remove('invert');
    }
  };

  render() {

    return (
      <div className="App">
            <MainContent {...this.props} /> 
      </div>
    );
  }
}
export default App;
