//External
import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from "@material-ui/core/es/Button/Button";
import Typography from "@material-ui/core/es/Typography";
import { colors } from "../Styles/colors"
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCreativeCommonsNc} from "@fortawesome/free-brands-svg-icons/faCreativeCommonsNc";
import {faBezierCurve} from "@fortawesome/free-solid-svg-icons/faBezierCurve";
import Image from "react-bootstrap/Image";

class Staff extends Component {
  render() {
    return (
        <Container id="Staff" fluid style={{
            fontFamily: "DharmaGothicE-RegularItalic",
            backgroundColor: '#17141f',
            padding: '5rem 0 0 0',
            overflowX: 'hidden'}}>
            {/*<Image src="/images/topgrad.png" fluid style={{marginTop: '-0.3em'}}/>*/}
            <Container fluid>
                <Row>
                    <Typography variant="body2" style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                        <FontAwesomeIcon icon={faBezierCurve} size="4x"/>
                    </Typography>
                </Row>
                <Row>
                    <Col>
                        <Typography variant={"h2"} style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                            THE CREW BEHIND THE SCENES
                        </Typography>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Typography variant="h3" style={{textAlign: 'center', width: '100%', color: colors.mainWhite}}>
                            They're a bit strange, but extremely dedicated and passionate! A team of A-Team professionals from different backgrounds.
                        </Typography>
                    </Col>
                </Row>
                    <Row className="flexDisplay flexJustifyBetween StaffWrapper">
                        <Card className="StaffCard" style={{margin: '1.5em'}}>
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    alt="Scarfy"
                                    className={""}
                                    //height="386px"
                                    // width="398px"
                                    image={require("../Assets/images/staff-scarfy.png")}
                                    title="Scarfy"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2" style={{fontFamily: "DharmaGothicE-RegularItalic", fontSize: '2.750rem'}}>
                                        Scarfy
                                    </Typography>
                                    <Typography component="p" variant={"body1"}>
                                        Creative Director, spearheading the visual language, branding and product design of Roleplace, alongside providing artwork our lovely mascot, Penny!
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>

                        <Card className="StaffCard" style={{margin: '1.5em'}}>
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    alt="Bottlecap"
                                    className={""}
                                    //height="386px"
                                    //width="398px"
                                    image={require("../Assets/images/staff-bottlecap.png")}
                                    title="Bottlecap"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2" style={{fontFamily: "DharmaGothicE-RegularItalic", fontSize: '2.750rem'}}>
                                        Bottlecap
                                    </Typography>
                                    <Typography component="p" variant="body1">
                                        Project Lead, and resident mega-nerd for RolePlace API architecture. He makes the complex cogs tick in the background and maintains data flow, API, and the RolePlace security layer.
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>

                        <Card className="StaffCard" style={{margin: '1.5em'}}>
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    alt="Scelera"
                                    className={""}
                                    //height="386px"
                                    //width="398px"
                                    image={require("../Assets/images/staff-scelera.png")}
                                    title="Scelera"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2" style={{fontFamily: "DharmaGothicE-RegularItalic", fontSize: '2.750rem'}}>
                                        Scelera
                                    </Typography>
                                    <Typography component="p" variant="body1">
                                        Full-Stack Engineer wearing the Web-Dev Hat, but able to work on whatever is needed.
                                        She bridges the gap from design to data and provides enterpise experience.
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>

                        <Card className="StaffCard" style={{margin: '1.5em'}}>
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    alt="Marley"
                                    className={""}
                                    //height="386px"
                                    //width="398px"
                                    image={require("../Assets/images/staff-marley.png")}
                                    title="Marley"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2" style={{fontFamily: "DharmaGothicE-RegularItalic", fontSize: '2.750rem'}}>
                                        Marley
                                    </Typography>
                                    <Typography component="p" variant="body1">
                                        Community manager in charge of outreach coordination, administration and user moderation. She acts as the 'Server-Mom' and front-facing
                                        representation of the RPL community.
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Row>
            </Container>
        </Container>
    );
  }
}

export default Staff;
