//External
import React, { Component } from 'react';
import Button from "@material-ui/core/es/Button/Button";
import Typography from "@material-ui/core/es/Typography";
import { colors } from "../Styles/colors"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserAstronaut} from "@fortawesome/free-solid-svg-icons/faUserAstronaut";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import ResponsiveEmbed from "react-bootstrap/ResponsiveEmbed";


class AboutUs extends Component {
    render() {
        //var test = `$url("/images/separator1.svg")}`;
        return (
            <Container fluid style={{backgroundColor: '#3A1557', padding: '3rem 0 0 0'}}>
                {/* Stack the columns on mobile by making one full-width and the other half-width */}
                <Container>
                    <Row>
                        <Typography variant="body2" style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                            <FontAwesomeIcon icon={faExclamationCircle} size="4x"/>
                        </Typography>
                    </Row>
                <Row>
                    <Col>
                        <Typography variant={"h2"} style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                            A NEW CHALLENGER APPROACHES
                        </Typography>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Typography variant="body1" style={{textAlign: 'center', width: '100%'}}>
                            Roleplace is a chat application with features and utilities geared toward online tabletop and/or text roleplay.
                            It is a platform for boundless user-generated narratives that inspire players to further supplement the subculture with original art, web design,
                            and more! In short, Roleplace is all of you.
                        </Typography>
                    </Col>
                </Row>
                    <Row>
                        <Col>
                            <ResponsiveEmbed aspectRatio="16by9">
                                <embed type="" src="https://www.youtube.com/embed/sNl1XL3VVek" allowFullScreen/>
                            </ResponsiveEmbed>
                        </Col>
                    </Row>
                </Container>
            </Container>
        );
    }
}

export default AboutUs;
