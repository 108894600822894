import React, { Component } from 'react';
import ResponsiveImage from "./Shared/ResponsiveImage";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Typography from "@material-ui/core/es/Typography";
import {colors} from "../Styles/colors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelopeOpenText} from "@fortawesome/free-solid-svg-icons/faEnvelopeOpenText";
import Footer from "./Footer";
import Image from "react-bootstrap/Image";

const backgroundStyle = {
    backgroundImage: `url(${require('../Assets/images/comingsoon-bg.png')})`,
};
class Oops extends Component {
    render() {
        return (
            <Container id={"ComingSoon"} fluid
                           style={{
                               backgroundImage: 'url(/images/rplace-bg2.png)',
                               height:'auto', width:'100%',
                               backgroundSize: 'cover',
                               backgroundRepeat: 'no-repeat',
                               backgroundColor: '#3A1557',
                               padding: '3rem 0 5rem 0',
                               overflowX: 'hidden'}}>
                {/* Stack the columns on mobile by making one full-width and the other half-width */}
                <Container style={{padding: '5rem 0 5rem 0'}}>
                    <Row>
                        <Col>
                            <Image src="/images/404-img.png" fluid style={{}}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Typography variant={"h2"} style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%', padding: '1.5rem 0 0 0'}}>
                                SOMETHING'S SERIOUSLY WRONG HERE..
                            </Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Typography variant="h3" style={{textAlign: 'center', width: '100%', color: colors.mainWhite}}>
                                We can't find that page you're looking for...
                            </Typography>
                        </Col>
                    </Row>
                </Container>

                <Row noGutters style={{width: '100%', marginBottom: '-10em'}}>
                    <Footer/>
                </Row>
            </Container>
        );
    }
}
export default Oops;