//External
import React, { Component } from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Typography from "@material-ui/core/es/Typography";
import {colors} from "../Styles/colors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCodeBranch} from "@fortawesome/free-solid-svg-icons/faCodeBranch";
import Col from "react-bootstrap/Col";
import {faCalendarPlus} from "@fortawesome/free-solid-svg-icons/faCalendarPlus";
import {faComments} from "@fortawesome/free-solid-svg-icons/faComments";
import {faFileCode} from "@fortawesome/free-solid-svg-icons/faFileCode";
import {faTrophy} from "@fortawesome/free-solid-svg-icons/faTrophy";
import {faDiceD20} from "@fortawesome/free-solid-svg-icons/faDiceD20";
import {faCompressArrowsAlt} from "@fortawesome/free-solid-svg-icons/faCompressArrowsAlt";
import {faIdCard} from "@fortawesome/free-solid-svg-icons/faIdCard";
import {faEnvelopeOpenText} from "@fortawesome/free-solid-svg-icons/faEnvelopeOpenText";
import Footer from "./Footer";
import {faSignInAlt} from "@fortawesome/free-solid-svg-icons/faSignInAlt";
import Button from "@material-ui/core/es/Button";
import {faSkull} from "@fortawesome/free-solid-svg-icons/faSkull";

class SiteRules extends Component {
    render() {
        return (
            <Container fluid
                       style={{
                           backgroundImage: 'url(/images/rplace-bg2.png)',
                           height:'auto', width:'100%',
                           backgroundSize: 'cover',
                           backgroundRepeat: 'no-repeat',
                           backgroundColor: '#3A1557',
                           padding: '3rem 0 5rem 0',
                           overflowX: 'hidden'}}>
                <Container style={{padding: '0 0 2rem 0',}}>
                    <Row>
                        <Typography variant="body2" style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                            <FontAwesomeIcon icon={faSkull} size="4x"/>
                        </Typography>
                    </Row>
                    <Row>
                        <Col>
                            <Typography variant={"h2"} style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                RULES OF THE NEXUS: SITE RULES
                            </Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Typography variant="h3" style={{textAlign: 'center', width: '100%', color: colors.mainWhite}}>
                                Subject to change!
                            </Typography>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Typography variant={"h3"} style={{color: colors.activeLinkColor, width: '100%'}}>
                                <br/>
                                Disallowed Profiles:
                            </Typography>
                            <Typography variant="body1" style={{color: colors.mainWhite, width: '100%'}}>
                                <ul>
                                    <li>
                                        Any underaged character seeking NSFW/smut content. This includes any and all characters,
                                        regardless of race, gender, origin, or background. If the character depicted on your Persona Profile appears to be,
                                        or is listed as under 18 years of age, they are to stay in SFW rooms, and not engage in sexual/NSFW communication or RP on-site.
                                    </li>
                                    <li>
                                        Any character based on non-celebrity faceclaims (e.g., using your own selfie to represent your character; using a friends or
                                        ex-friends pictures to represent your character).
                                    </li>
                                    <li>
                                        Any real-life image of bestiality, gore, and shock imagery used in an RPL profile.
                                    </li>
                                    <li>
                                        Impersonating celebrities, or any non-historical real-life individual is disallowed
                                        outside of parodical interpretations. Parody accounts, must clearly state they are parodical in nature.
                                    </li>
                                    <li>
                                        We reserve the right to take down profiles at our leisure, and request images to be taken down.
                                    </li>
                                </ul>
                            </Typography>
                        </Col>
                    </Row>


                    <Row>
                        <Col>
                            <Typography variant={"h3"} style={{color: colors.activeLinkColor, width: '100%'}}>
                                <br/>
                                Bannable Offenses:
                            </Typography>
                            <Typography variant="body1" style={{color: colors.mainWhite, width: '100%'}}>
                                <ul>
                                    <li>
                                        Violating 18+ rule in our site (No Exceptions!).
                                    </li>
                                    <li>
                                        Impersonation of Administrators or Moderators in the site.
                                    </li>
                                    <li>
                                        Bypassing bans to rooms.
                                    </li>
                                    <li>
                                        Bypassing blocks by another user.
                                    </li>
                                    <li>
                                        Threatening physical harm or other users on the site.
                                    </li>
                                    <li>
                                        Stalking and harassment of other users.
                                    </li>
                                    <li>
                                        Screenshotting private interactions, whether they are on RolePlace or it's associated discord, for the purpose of shaming, embarrassing, blackmailing or even outing another user is insanely uncool. Try to only use screenshots for the purpose of proving a case to staff should a user violate any of the previously established rules.
                                    </li>
                                </ul>
                            </Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Typography variant={"h3"} style={{color: colors.activeLinkColor, width: '100%'}}>
                                <br/>
                                Warnable Offenses:
                            </Typography>
                            <Typography variant="body1" style={{color: colors.mainWhite, width: '100%'}}>
                                <ul>
                                    <li>
                                        Explicit use of Gross/Shock imagery.
                                    </li>
                                </ul>
                            </Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Typography variant={"h3"} style={{color: colors.activeLinkColor, width: '100%'}}>
                                <br/>
                                Beta Rules:
                            </Typography>
                            <Typography variant="body1" style={{color: colors.mainWhite, width: '100%'}}>
                                <ul>
                                    <li>
                                        Rooms may not have NSFW content as their main thumbnail image.
                                    </li>
                                    <li>
                                        Users may not have NSFW content as their avatar.
                                    </li>
                                </ul>
                            </Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Typography variant={"h3"} style={{color: colors.activeLinkColor, width: '100%'}}>
                                <br/>
                                Image Rules:
                            </Typography>
                            <Typography variant="body1" style={{color: colors.mainWhite, width: '100%'}}>
                                <ul>
                                    <li>
                                        Your images (user submitted avatars, room backgrounds, profiles, room thumbnails, player card imagery or otherwise) do not contain material that solicits personal information from anyone under 18 or exploit people under the age of 18 in a sexual or violent manner, and does not violate any federal or state law concerning child pornography or otherwise intended to protect the health or well-being of minors;
                                    </li>
                                    <li>
                                        Your images (user submitted avatars, room backgrounds, profiles, room thumbnails, player card imagery or otherwise) do not otherwise violate, or link to material that violates any provision of this Agreement or any applicable law or regulation.
                                    </li>
                                    <li>
                                        Roleplace staff reserve the right to remove any content which is deemed to be in violation of these rules or otherwise harmful to the platform.
                                    </li>
                                    <li>
                                        Sharing Illustrated or digitally altered pornography which depicts minors (such as lolicon or shotacon) is not allowed. Neither are feral/cub images.
                                    </li>
                                    <li>
                                        Profiles/Avatars/Rooms Backgrounds/etc of “Selfies” are not allowed - (Falsely impersonating non-famous individuals)
                                    </li>
                                </ul>
                            </Typography>
                        </Col>
                    </Row>
                </Container>
                <Row noGutters style={{width: '100%', marginBottom: '-10em'}}>
                    <Footer/>
                </Row>
            </Container>
        );
    }
}

export default SiteRules;
