// Site color scheme
export const colors = {
    chatRoomBackground: '#0E0C13',
    headerNavigationBackground: '#392E5C',
    mainNavigationBackground: '#3A1557',
    chatBar: '#16122E',
    personaBackground: '#201c2b',
    userListBg: '#181333',

    siteStatus: '#2C2541',

    linkColor: '#A373FF',
    activeLinkColor: '#45E6EA',

    secondary: '#06AACA',
    secondary2: '#06B5D7',
    secondary3: '#0595B0',
    secondary4: '#04748A',
    secondary5: '#023F4A',

    mainButtonColor: '#1e6795',

    primary: '#33267F',
    primary2: '#523DCB',
    primary3: '#382A8C',
    primary4: '#291E65',
    primary5: '#19133F',

    mainGrey: '#DDE0E4',
    mainWhite: '#F0FFFF',

    userPostBackground: '#0A000A',
    scrollBarThumb: '#382F5A',
    scrollBackground: '#17141F',
};
