//External
import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/es/Button/Button";
import Typography from "@material-ui/core/es/Typography";
import {colors} from "../Styles/colors"
import ReCAPTCHA from "react-google-recaptcha";
import {ApiErrorCodes, GetError, IsValidEmail, RegistrationStateFields} from "../Common";
import Link from "@material-ui/core/Link";
import Container from "react-bootstrap/Container";
import Footer from "./Footer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelopeOpenText} from "@fortawesome/free-solid-svg-icons/faEnvelopeOpenText";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";

// Constants
const recaptchaRef = React.createRef();

class Registration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            complete: false,
            touched: {
                email: false,
                password: false,
                passwordConfirm: false
            },
            userHasClickedSubmit: false,
            error: {
                hasError: false,
                apiErrorCode: null,
                errorDescription: null,
            }
        }
        this.minimumPasswordLength = 8;
        this.baseURL = 'https://api.roleplace.com/webapi/register';
    }

    /********************************************** VALIDATROS ***************************************************/

    isValidForm = () => {
        const recaptchaValue = recaptchaRef.current && recaptchaRef.current.getValue();
        return recaptchaValue &&
            !this.invalidEmail() &&
            !this.invalidPassword() &&
            !this.invalidPasswordConfirm() &&
            this.validCompliance();
    }

    validCompliance = () => {
        return this.state[RegistrationStateFields.ageCheckBox] &&
            this.state[RegistrationStateFields.rulesCheckBox];
    }

    formHasError = () => {

        if(this.state.error.hasError){
            return this.state.error.errorDescription;
        }
        return false;
    }

    complianceHasError = () => {
        if (!this.state.userHasClickedSubmit) {
            return false;
        }

        return !this.validCompliance();
    }

    invalidEmail = () => {
        return this.state.touched.email && !IsValidEmail(this.state.email);
    }

    invalidPassword = () => {
        return this.state.touched.password &&
            (!this.state.password || this.state.password.length < this.minimumPasswordLength || !this.passwordsEquivalent());
    }

    invalidPasswordConfirm = () => {
        return this.state.touched.passwordConfirm &&
            (!this.state.passwordConfirm || this.state.passwordConfirm.length < this.minimumPasswordLength || !this.passwordsEquivalent())
    }

    passwordsEquivalent = () => {
        if (!this.state.touched.password || !this.state.touched.passwordConfirm) {
            return true;
        }
        return (this.state.password === this.state.passwordConfirm)
    }

    getEmailHelpText = () => {
        if (!this.state.touched.email) {
            return;
        }
        if (this.invalidEmail()) {
            return "Please enter a valid email."
        }
    }

    getPassWordHelpText = (passwordKey) => {
        if (!this.state.touched[passwordKey]) {
            return;
        }
        if (!this.state[passwordKey]) {
            return "Please enter password.";
        }
        if (this.state[passwordKey].length < this.minimumPasswordLength) {
            return "Passwords must be at least 8 characters.";
        }
        if (!this.passwordsEquivalent()) {
            return "Passwords must match.";
        }
    }

    /************************************************************************************************************/

    // Handle local state
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    // Handle local state for checkboxes
    handleCheckboxChange = (event) => {
        this.setState({
            [event.target.name]: event.target.checked
        });
    }

    /**
     * Check For 'Dirty' Controls
     * @param field
     * @returns {Function}
     */
    handleBlur = (field) => (evt) => {
        this.setState({
            touched: {...this.state.touched, [field]: true},
        });
    }

    handleSubmit = () => {
        return fetch(`${this.baseURL}`, this.options)
            .then(this.checkStatus)
            .then(() => {
                this.setState({complete: true});
            })
            .catch(response => {
            });
    }
    onSubmit = (e) => {
        e.preventDefault();
        if (!this.isValidForm()) {
            this.setState(state => ({
                touched: {
                    email: true,
                    password: true,
                    passwordConfirm: true
                },
                userHasClickedSubmit: true,
                error: GetError("Please complete the form/captcha.")
            }));
            return;
        }
        this.options = {
            headers: {
                "Content-Type": 'application/json',
                "user": this.state.email,
                "auth": this.state.password,
                "recaptcha": recaptchaRef.current.getValue()
            },
            method: 'POST'
        }
        this.handleSubmit();
    }

    onCaptchaError = () => {
        this.setState(state => ({
            error: GetError("Woops! Looks like something is wrong with the captcha. Please" +
                " refresh the page.")
        }));
    }

    checkStatus = (response) => {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response;
        } else {
            switch (response.status) {
                case ApiErrorCodes.CAPTCHA_FAIL: {
                    this.setState(state => ({
                        error: GetError("There was an issue with reCaptcha, please resubmit", ApiErrorCodes.CAPTCHA_FAIL)
                    }));
                    break;
                }
                case ApiErrorCodes.EMAIL_ALREADY_REGISTERED: {
                    this.setState(state => ({
                        error: GetError("Looks like that email is already registered!", ApiErrorCodes.EMAIL_ALREADY_REGISTERED)
                    }));
                    break;
                }
                case ApiErrorCodes.SERVER_ERROR: {
                    this.setState(state => ({
                        error: GetError("Woops! Looks like something's wrong on our end...", ApiErrorCodes.SERVER_ERROR)
                    }));
                    break;
                }
                default: {
                    this.setState(state => ({
                        error: GetError("Wow! How did you do that? Please send us an error report, " +
                            " because this message should not appear!")
                    }));
                    break;
                }
            }
            throw new Error();
        }
    }

    getError = (errorDescription, apiErrorCode = null) => {
        return {
            hasError: true,
            apiErrorCode: apiErrorCode,
            errorDescription: errorDescription
        };
    }

    /********************************************** RENDER ***************************************************/

    render() {
        if (this.state.complete) {
            return (
                <Container id={"ComingSoon"} fluid
                           style={{
                               backgroundImage: 'url(/images/rplace-bg2.png)',
                               height: 'auto', width: '100%',
                               backgroundSize: 'cover',
                               backgroundRepeat: 'no-repeat',
                               backgroundColor: '#3A1557',
                               padding: '3rem 0 5rem 0',
                               overflowX: 'hidden'
                           }}>
                    {/* Stack the columns on mobile by making one full-width and the other half-width */}
                    <Container>
                        <Row>
                            <Col>
                                <Typography variant={"h2"}
                                            style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                    CONGRATS YOU'RE ALL SET!
                                </Typography>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Typography variant="h3"
                                            style={{textAlign: 'center', width: '100%', color: colors.mainWhite}}>
                                    So! What's next?<br/>
                                    ...
                                </Typography>
                            </Col>
                        </Row>
                    </Container>
                    <Container style={{padding: '5rem 0 5rem 0'}}>
                        <Row>
                            <Typography variant="body2" style={{color: "#FFD27B", textAlign: 'center', width: '100%'}}>
                                <FontAwesomeIcon icon={faEnvelopeOpenText} size="4x"/>
                            </Typography>
                        </Row>
                        <Row>
                            <Col>
                                <Typography variant={"h2"}
                                            style={{color: "#FFD27B", textAlign: 'center', width: '100%'}}>
                                    CHECK YOUR EMAIL! WE SENT YOU SOMETHING.
                                </Typography>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Typography variant="h3"
                                            style={{color: colors.mainWhite, textAlign: 'center', width: '100%'}}>
                                    Nothing big. Just a confirmation.
                                </Typography>
                                <br/>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg>
                                <Typography variant={"h3"}
                                            style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                    For New Roleplacers:
                                </Typography>
                                <Typography variant="body1" style={{textAlign: 'center', width: '100%'}}>
                                    <Link href={"https://discord.gg/nHp7XWF"} target="_blank">Click here</Link> to join
                                    our Discord server and stay up to date on RolePlace updates OR <Link
                                    href={"http://www.patreon.com/roleplace"} target="_blank">click here</Link>, if
                                    you're feeling generous, even a little helps keep the lights running!
                                </Typography>
                            </Col>
                            <Col lg>
                                <Typography variant={"h3"}
                                            style={{color: colors.activeLinkColor, textAlign: 'center', width: '100%'}}>
                                    For Supporters:
                                </Typography>
                                <Typography variant="body1" style={{textAlign: 'center', width: '100%'}}>
                                    If you're currently a supporter via Patreon, or have purchased RPL Merch in the
                                    past, you
                                    can log in <Link href={"https://chat.roleplace.com/"} target="_blank">here</Link>.
                                    If you come across any issues, please feel free to contact Marley on our
                                    <Link href={"https://discord.gg/nHp7XWF"} target="_blank">Official
                                        Discord</Link> server.
                                </Typography>
                            </Col>
                        </Row>
                    </Container>
                    <Row noGutters style={{width: '100%', marginBottom: '-10em'}}>
                        <Footer/>
                    </Row>
                </Container>)
        } else {
            return (
                <Container className="MainContentContainer" fluid style={{
                    backgroundColor: '#3a1557',
                    padding: '3rem 0 3rem 0',
                    backgroundImage: 'url(/images/rplace-bg2.png)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'left',
                    backgroundRepeat: 'no-repeat'
                }}>
                    <Container>
                        <Row>
                            <Typography variant={"h2"} style={{
                                color: colors.activeLinkColor,
                                textAlign: 'center',
                                width: '100%'
                            }}>
                                WELCOME ABOARD!
                            </Typography>
                        </Row>
                    </Container>
                    <Container style={{display: 'flex', justifyContent: 'center', padding: '1rem 0 0 0'}}>
                        <form onSubmit={this.onSubmit}>
                            <Row style={{display: 'flex', justifyContent: 'center'}}>
                                <TextField
                                    style={{width: '15em', margin: '1rem 0 0 0'}}
                                    label="email"
                                    autoComplete={"off"}
                                    variant="outlined"
                                    name={RegistrationStateFields.email}
                                    placeholder="youremail@email.com"
                                    onChange={this.handleChange}
                                    error={this.invalidEmail()}
                                    onBlur={this.handleBlur(RegistrationStateFields.email)}
                                    helperText={this.getEmailHelpText()}
                                />
                            </Row>
                            <Row style={{display: 'flex', justifyContent: 'center'}}>
                                <TextField
                                    type="password"
                                    style={{width: '15em', margin: '1rem 0 0 0'}}
                                    error={this.invalidPassword()}
                                    label="Password"
                                    autoComplete={"off"}
                                    variant="outlined"
                                    name={RegistrationStateFields.password}
                                    placeholder="Super Secret"
                                    onChange={this.handleChange}
                                    onBlur={this.handleBlur(RegistrationStateFields.password)}
                                    helperText={this.getPassWordHelpText(RegistrationStateFields.password)}
                                />
                            </Row>
                            <Row style={{justifyContent: 'center'}}>
                                <TextField
                                    type="password"
                                    style={{width: '15em', margin: '1rem 0 0 0'}}
                                    error={this.invalidPasswordConfirm()}
                                    label="Confirm Password"
                                    autoComplete={"off"}
                                    variant="outlined"
                                    name={RegistrationStateFields.passwordConfirm}
                                    placeholder="Super Secret"
                                    onChange={this.handleChange}
                                    onBlur={this.handleBlur(RegistrationStateFields.passwordConfirm)}
                                    helperText={this.getPassWordHelpText(RegistrationStateFields.passwordConfirm)}
                                />
                            </Row>
                            <br/>


                            <Typography variant={"body1"} style={{textAlign: 'center', width: '100%', color: 'red'}}>
                                <p>{this.formHasError()}</p>
                            </Typography>

                            <Row style={{justifyContent: 'center'}}>
                                <ReCAPTCHA
                                    sitekey="6LcHOjYUAAAAANnlJK1BsO73AAZiI2FtUiuZqqzx"
                                    theme="dark"
                                    ref={recaptchaRef}
                                    onErrored={this.onCaptchaError}
                                />
                            </Row>
                            <br/>
                            <FormControl required error={this.complianceHasError()}>
                                <br/>
                                    <FormLabel style={{fontSize: "1em"}}>Compliance Agreement</FormLabel>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={this.handleCheckboxChange}
                                                name={RegistrationStateFields.ageCheckBox}
                                                required={true}
                                            />
                                        }
                                        label="I hereby acknowledge that I am 18 years of age or older."
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={this.handleCheckboxChange}
                                                name={RegistrationStateFields.rulesCheckBox}
                                                required={true}
                                            />
                                        }
                                        label={
                                            <span>
                                                I have read and agree to
                                                the <Link href={"/SiteRules"}> RP Compendium Rules of Conduct</Link> and
                                                the <Link href={"/Terms"}>Terms of Service</Link>
                                            </span>
                                        }
                                    />
                                </FormGroup>
                            </FormControl>
                            <Button color="primary" variant="contained" fullWidth={true}
                                    style={{margin: '0'}} type="submit">
                                Sign Up
                            </Button>
                            <Row>
                                <Typography variant={"body1"} style={{textAlign: 'center', width: '100%'}}>
                                    <br/>
                                    <p>Already registered? <Link href={"https://chat.roleplace.com/"}>Sign in here!</Link></p>
                                </Typography>
                            </Row>
                        </form>
                    </Container>
                    <Row noGutters style={{width: '100%', marginBottom: '-2em'}}>
                        <Footer/>
                    </Row>
                </Container>
            );
        }
    }
}

export default Registration;
