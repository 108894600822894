import React, { Component } from 'react';
import { BrowserRouter, HashRouter, Route, Switch, Redirect } from "react-router-dom";
import MuiThemeProvider from "@material-ui/core/es/styles/MuiThemeProvider";
import { createMuiTheme } from '@material-ui/core/styles';

// ours
import App from './App';
import Header from './Components/Header';
import Recover from './Components/Recover';
import RecoverRequest from './Components/RecoverRequest';
import Registration from './Components/Registration';
import RegisterRedirect from './Components/RegistrationRedirect';
import Spotlight from './Components/Spotlight';
import Terms from './Components/Terms';
import Oops from './Components/Oops';
import SiteRules from './Components/SiteRules'

import { colors } from "./Styles/colors";
const theme = createMuiTheme({

    // Default behavior for all of a particular material control can go here
    // Man I'm an idiot for not setting this up correctly sooner
    overrides: {
        MuiFormHelperText: {
            root: {
                color: colors.secondary4,
            }
        },
        MuiOutlinedInput: {
            notchedOutline: {
                // border: `2px solid ${colors.activeLinkColor} !important`,
            },
            root: {
                fontFamily: "EpocaPro-Regular",
                backgroundColor: colors.primary5,
                padding: 0
            },
            disabled: {
                // border: '1px solid red'
            }
        },
        MuiTab: {
            root: {
                opacity: 1,
                backgroundColor: colors.primary2,
            },
            labelContainer: {
                margin: '0 0.35em',
                padding: '0 !important'
            },
            label: {
                textTransform: 'capitalize'
            },
            selected: {
                backgroundColor: colors.primary4,
                borderRadius: '5px',
                boxShadow: '0 10px 6px -6px #000'
            },
        },
        MuiTabs: {
            indicator: {
                display: 'none'
            }
        },
        MuiDialog: {
            paper: {
                backgroundColor: colors.mainNavigationBackground,
                border: `1px solid ${colors.mainButtonColor}`
            }
        },
        MuiDialogTitle: {
            root: {
                textTransform: "uppercase",
                textAlign: "center"
            }
        },
        MuiDialogActions: {
            action: {
                color: "#fff",
                backgroundColor: colors.mainButtonColor,
                margin: '0 auto 0.5em auto',
                filter: 'drop-shadow( 2px 2px 4px rgba(0, 0, 0, .4))'
            }
        },
        MuiButton: {
            root: {
                //textTransform: 'none';
            },
            containedPrimary: {
                backgroundColor: colors.mainButtonColor
            },
            contained: {
                filter: 'drop-shadow(3px 3px 2px rgba(0, 0, 0, .7))',
                fontSize: '0.8em',
            }
        },
        MuiChip: {
            colorPrimary: {
                backgroundColor: colors.mainButtonColor
            },
            label: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }
        },
        MuiInputLabel: {
            root: {
                fontFamily: "DharmaGothicE-RegularItalic",
                fontSize: "1.2em",
                marginTop: "-0.1em",
                textTransform: "uppercase"
            }
        },
        MuiAppBar: {
            colorDefault: {
                backgroundColor: colors.headerNavigationBackground,
                boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
                color: "white"
            }
        },
        MuiTypography: {

        },
        Container: {
            hero: {
                backgroundColor: '#3A1557',
                backgroundImage: 'url("../Assets/images/rplace-bg3.png")',
                backgroundSize: 'cover',
                backgroundPosition: 'right',
                backgroundRepeat: 'no-repeat'
            },
        }
    },
    // todo: error coloring
    // Inverted primary and secondary since it looked better with a primarily purple background
    palette: {
        primary: {
            light: colors.secondary5,
            main: colors.secondary,
            dark: colors.secondary2,
            contrastText: '#fff',
        },
        secondary: {
            light: colors.primary5,
            main: colors.primary,
            dark: colors.primary2,
            contrastText: '#fff',
        },
        background: {
            default: colors.siteStatus,
            paper: colors.scrollBackground
        },
        action: {
            disabledBackground: colors.mainGrey,
            selected: colors.secondary3,
            active: colors.activeLinkColor,
            hover: colors.secondary4,
        },
        divider: colors.secondary,
        text: {
            primary: "#FFFFFF",
            secondary: colors.secondary,
            hint: colors.linkColor,
            disabled: colors.mainGrey
        }
    },
    typography: {
        useNextVariants: true,
        fontFamily: 'Nanami-light',
        fontSize: 14,
        button: {
            fontFamily: 'Nanami-medium',
            fontSize: "large",
            letterSpacing: 1.2,
            color: colors.secondary,
            // fontWeight: "bold"
        },
        h2: {
            fontFamily: "DharmaGothicE-RegularItalic",
            fontSize: '2.125em'
        },
        h3: {
            fontFamily: "DharmaGothicE-RegularItalic",
            color: colors.activeLinkColor,
            fontSize: '1.512em'
        },
        body1: {
            fontFamily: "EpocaPro-Regular",
            fontSize: '1.250rem'
        },
        body2: {
            fontFamily: "EpocaPro-Regular",
            fontSize: '1.1rem'
        },
        footer: {
            fontSize: '0.5em'
        }
    },
    spacing: {
        unit: 4
    }
});


const Root = () => (

    <MuiThemeProvider theme = { theme } >
        <BrowserRouter>
        <Header/>
            <Switch>
                <Route
                    exact path="/"
                    render={() =>  (
                        <Redirect to="/Home"/>
                    )}
                />
                <Route
                    path="/Home"
                    render={(props) =>  (
                        <App {...props}/>
                    )}
                />
                <Route
                    path="/Register"
                    render={(props) =>  (
                        <Registration {...props}/>
                    )}
                />
                <Route
                    path="/ThankYou"
                    render={(props) =>  (
                        <RegisterRedirect {...props}/>
                    )}
                />
                 <Route
                    path="/Recover"
                    render={(props) =>  (
                        <Recover/>
                    )}
                />
                <Route
                    path="/RecoverRequest"
                    render={(props) =>  (
                        <RecoverRequest/>
                    )}
                />
                <Route
                    path="/Spotlight"
                    render={(props) =>  (
                        <Spotlight {...props}/>
                    )}
                />

                <Route
                    path="/Terms"
                    render={(props)=> (
                        <Terms/>
                    )}
                />

                <Route
                    path="/SiteRules"
                    render={(props)=> (
                        <SiteRules/>
                    )}
                />

                <Route
                    path={"/Oops"}
                    render={(props)=>(
                        <Oops/>
                    )}
                />
            </Switch>
        </BrowserRouter>
    </MuiThemeProvider>
);

export default Root;
